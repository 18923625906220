import React, { useState, useEffect } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import DataTable from "react-data-table-component";
import { Button, Col, Container, Row } from "reactstrap";
import {
  getAllDailyTaskForEmployee,
  getAllDailyTaskEmployee,
} from "../../../Api/WebsiteApi";
import { TaskListUpdateHandler } from "./TaskstatusMethods/TaskListHandlers";
import CustomSpinner from "../../../Components/Button/CustomSpinner";
import ModalContainer from "../../../Components/Modal/ModalContainer";
import DailyTask from "../TaskCreations/DailyTask";
import Cookies from "js-cookie";
import WorkType from "../Dashboard/WorkType";
import Project from "../TaskCreations/Project";
import { Tooltip } from "react-tooltip";
import { TaskList } from "../../../Type";
import useTagStore from "../../../Store/store";

const ProductStatus: React.FC = () => {
  const [editRowId, setEditRowId] = useState<string | null>(null);
  const [formData, setFormData] = useState<Partial<TaskList>>({});
  const [isOpenLogout, setIsOpenLogout] = useState<boolean>(false);
  const userId = Cookies.get("PMUSERNAME");
  const { currentDate } = useTagStore();
  const [data, setData] = useState<TaskList[]>([]);

  // Function to handle opening/closing the modal
  const toggleModal = () => {
    setIsOpenLogout(!isOpenLogout);
  };

  // All daily task of employee
  const {
    data: allDailyTaskEmployee = [],
    refetch: getAllDailyTaskEmployeeRefetch,
  } = useQuery({
    queryKey: ["allDailyTaskEmployee", userId, currentDate],
    queryFn: () => getAllDailyTaskEmployee(userId, currentDate),
    placeholderData: [],
  });

  //All daily task of every one
  const {
    data: allDailyTaskOfEveryone = [],
    error: allDailyTaskError,
    isLoading: allDailyTaskLoading,
    refetch: allDailyTaskRefetch,
  } = useQuery({
    queryKey: ["allDailyTask"],
    queryFn: () => getAllDailyTaskForEmployee(),
    placeholderData: keepPreviousData,
    enabled: Cookies.get("PMROLE") !== "USER",
  });

  useEffect(() => {
    if (!allDailyTaskError && Array.isArray(allDailyTaskOfEveryone)) {
      if (JSON.stringify(data) !== JSON.stringify(allDailyTaskOfEveryone)) {
        setData(allDailyTaskOfEveryone);
      }
    }
  }, [allDailyTaskOfEveryone, allDailyTaskError, data]);

  const handleEditClick = (row: any) => {
    setEditRowId(row?.taskId);
    setFormData(row);
  };

  const handleSaveClick = () => {
    TaskListUpdateHandler(formData, {
      setSubmitting: (isSubmitting: boolean) => {},
    })
      .then(() => {
        allDailyTaskRefetch();
        setEditRowId(null);
        setFormData({});
      })
      .catch((error: any) => {
        console.error("Error updating task:", error);
      });
  };

  const handleCancelClick = () => {
    setEditRowId(null);
    setFormData({});
  };

  const ActionsColumn = ({ row }: { row: any }) => {
    const isEditing = editRowId === row?.taskId;

    if (Cookies?.get("PMROLE") !== "ADMIN") {
      return null;
    }

    return isEditing ? (
      <>
        <Button
          className="w-100 my-1"
          size="sm"
          color="success"
          onClick={handleSaveClick}
        >
          Save
        </Button>
        <Button
          className="w-100"
          color="danger"
          size="sm"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>
      </>
    ) : (
      <Button
        className="w-100 my-1"
        size="sm"
        onClick={() => handleEditClick(row)}
      >
        Edit
      </Button>
    );
  };

  const columns = [
    {
      name: "Daily Task",
      cell: (row: any) => (
        <div style={{ whiteSpace: "normal" }}>
          {row?.tasks?.map((task: any) => (
            <div className="my-3" key={task?.id}>
              {task?.name}
            </div>
          ))}
        </div>
      ),
      sortable: true,
      width: "auto",
    },

    {
      name: "Description",
      cell: (row: any) => (
        <div style={{ whiteSpace: "normal" }}>
          {row?.tasks?.map((task: any) => (
            <div
              className="my-3"
              key={task?.id}
              data-tooltip-id="my-tooltip"
              data-tooltip-content={task?.description}
              data-tooltip-place="bottom"
            >
              <Tooltip id="my-tooltip" />
              {task?.description.substring(0, 40)}..
            </div>
          ))}
        </div>
      ),
      sortable: true,
      width: "350px",
    },
    {
      name: "Task Status",
      sortable: true,
      cell: (row: any) => (
        <div>
          {row?.tasks?.map((task: any) => (
            <div key={task?.taskId} className="mb-1">
              <p
                style={{
                  color:
                    task?.status === "COMPLETED"
                      ? "green"
                      : task?.status === "IN_PROGRESS"
                      ? "orange"
                      : task?.status === "OPEN"
                      ? "blue"
                      : task?.status === "FORGED"
                      ? "red"
                      : "black",
                }}
              >
                {task?.status}
              </p>
            </div>
          ))}
        </div>
      ),
      width: "auto",
    },
    {
      name: "Time Taken",
      cell: (row: any) => (
        <div style={{ whiteSpace: "normal" }}>
          {row?.tasks?.map((task: any) => (
            <div className="my-3" key={task?.id}>
              {task?.timeTaken}:hrs
            </div>
          ))}
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    ...(Cookies?.get("PMROLE") !== "USER"
      ? [
          {
            name: "Actions",
            cell: (row: any) => (
              <div>
                {row?.tasks?.map((task: any) => (
                  <ActionsColumn key={task?.id} row={task} />
                ))}
              </div>
            ),
            width: "150px",
          },
        ]
      : []),
  ];

  return (
    <>
      {" "}
      <Container fluid className="boxes py-4">
        <WorkType />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {" "}
          <Col sm={5}>
            {" "}
            <h5 className="tbold">Daily Tasks</h5>
          </Col>
          {allDailyTaskEmployee && allDailyTaskEmployee?.length == 0 ? (
            <Col sm={5}>
              <div>
                <h5 style={{ color: "#0756ab" }}>No task found</h5>
              </div>
            </Col>
          ) : (
            ""
          )}
          <Col sm={2}>
            <button
              className="sm w-100 buttonforproject buttonStyle"
              onClick={toggleModal}
              color="black"
            >
              Create task
            </button>
          </Col>
        </div>

        <Row className="d-flex my-2 justify-content-center">
          {allDailyTaskEmployee && allDailyTaskEmployee?.length > 0 ? (
            <DataTable
              className="mb-4"
              columns={columns}
              data={allDailyTaskEmployee}
              responsive
              pagination
              highlightOnHover
              pointerOnHover
              progressPending={allDailyTaskLoading}
              progressComponent={<CustomSpinner />}
            />
          ) : (
            // <CustomSpinner />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "47vh",
                overflowY: "hidden",
                margin: 0,
                padding: 20,
              }}
            >
              <img
                style={{ borderRadius: 10, height: "450px" }}
                className="img-fluid"
                src="../images/7.gif"
                alt="Loading..."
              ></img>
            </div>
          )}
        </Row>

        <ModalContainer
          isOpen={isOpenLogout}
          setIsOpen={setIsOpenLogout}
          title={
            Cookies.get("PMROLE") !== "ADMIN" || "TEAMLEAD"
              ? "Daily Task"
              : "Projects"
          }
        >
          {/* Show daily task for employees and main task for admin */}
          {Cookies.get("PMROLE") !== "ADMIN" || "TEAMLEAD" ? (
            <DailyTask
              fullDailyTaskRefetch={getAllDailyTaskEmployeeRefetch}
              setIsOpen={setIsOpenLogout}
            />
          ) : (
            <Project />
          )}
        </ModalContainer>
      </Container>
    </>
  );
};

export default ProductStatus;
