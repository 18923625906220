import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RoleProvider = ({ Role, loginStatus }: any) => {
  const [roleUser, setRoleUser] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (Cookies.get("PMROLE") === Role) {
      setRoleUser(true);
    } else {
      setRoleUser(false);
    }
  }, [Role, loginStatus]); // Add Role and loginStatus to the dependency array

  return roleUser ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
};

export default RoleProvider;
