import React, { useEffect } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Col, Container, Row } from "reactstrap";
import {
  getAllProjectByproductIdEmployeeId,
  getAllProducts,
} from "../../../../Api/WebsiteApi";
import ProjectListing from "../ProjectListing";
import { AllProductsDataType, ProjecttListing } from "../../../../Type";
import CustomSpinner from "../../../../Components/Button/CustomSpinner";
import useTagStore from "../../../../Store/store";

const ProductListing: React.FC = () => {
  const { selectedTagId, projectId, searchDataForProject, hasRun, setHasRun } =
    useTagStore();
  const { setSelectedTagId, setprojectId } = useTagStore((state) => ({
    setSelectedTagId: state?.setSelectedTagId,
    setprojectId: state?.setprojectId,
  }));

  const handleStatusChange = (value: any) => {
    setprojectId(value?.value);
    setSelectedTagId(value?.label);
  };

  // Api for getting All projects
  const { data: allProductsData } = useQuery<AllProductsDataType[]>({
    queryKey: ["AllProduct"],
    queryFn: () => getAllProducts(),
    placeholderData: keepPreviousData,
  });

  const {
    data: fullProjectData,
    isLoading: fullProjectLoading,
    refetch: projectsRefecth,
  } = useQuery<ProjecttListing[]>({
    queryKey: ["ProjectByProductId", projectId, searchDataForProject],
    queryFn: () => getAllProjectByproductIdEmployeeId(projectId),
    enabled: !!projectId,
  });

  //For getting the project
  useEffect(() => {
    if (allProductsData && !hasRun) {
      setprojectId(allProductsData?.[0]?.value);
      setHasRun(true);
    }
  }, [allProductsData]);

  return (
    <Container fluid>
      <Row
        style={{
          display: "flex",
          justifyContent: "start",
          color: "#ffff",
          paddingLeft: 10,
        }}
        className="header_background"
      >
        {allProductsData?.map((project: AllProductsDataType, index: number) => (
          <Col key={index + 1} xs={12} sm={3} md={1}>
            <div className="d-flex align-items-center">
              <p
                style={{
                  margin: 1,
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                onClick={() => handleStatusChange(project)}
                className={`forflexProject ${
                  selectedTagId === project?.label ? "active" : ""
                }`}
              >
                {project?.label}
              </p>
              <div
                className="circle2"
                style={{
                  padding: 5,
                  fontSize: 14,
                  fontWeight: "bold",
                  backgroundColor: "#ACB5BB",
                  marginLeft: "8px",
                }}
              >
                {project?.projects?.length}
              </div>
            </div>
          </Col>
        ))}
      </Row>

      {fullProjectLoading ? (
        <div className="d-flex,justify-content-center">
          <CustomSpinner />
        </div>
      ) : (
        <ProjectListing
          data={fullProjectData}
          id={projectId}
          refetch={projectsRefecth}
        />
      )}
    </Container>
  );
};

export default ProductListing;
