import dayjs from "dayjs";
import Cookies from "js-cookie";
import * as Yup from "yup";
//get User
const assignedBy = Cookies?.get("PMUSERNAME");
// Get current time
const currentTime = dayjs().format("YYYY-MM-DD");

//Validation
export const ProjectValidation = Yup.object().shape({
  projectName: Yup.string()
    .min(2, "Minimum 2 letters are required")
    .required("Project Name is required"),
  projectDescription: Yup.string()
    .min(10, "Minimum 10 letters are required")
    .required("Project Description is required"),
  assignee: Yup.string()
    .min(2, "Minimum 2 letters are required")
    .required("Assignee is required"),
  productId: Yup.string()
    .min(0, "No Project with zero as id")
    .required("Project Name is required"),
  startDate: Yup.string()
    .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in the format YYYY-MM-DD")
    .required("Date is required"),
  status: Yup.string().required("Task type is required"),
  teamId: Yup.string().required("Team is required"),
});
// initial values
export const initializeProjectInitialValues = () => {
  return {
    projectName: "",
    projectDescription: "",
    assignee: assignedBy,
    productId: "",
    startDate: currentTime,
    status: "OPEN",
    teamId: "",
  };
};
