import React from "react";
import Navigation from "./Header/Header";
import { AnimatePresence, motion } from "framer-motion";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const url = window.location.pathname;
  return (
    <div
      style={{
        background: url.includes("dashboard") ? "#EFEFEF" : "#FFFFF",
        justifyContent: "center",
        minHeight: "98vh",
        position: "relative",
      }}
    >
      <Navigation />
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0.5, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0.5, y: -30 }}
          transition={{ ease: "easeInOut", duration: 0.45 }}
        >
          <main
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: 10,
            }}
          >
            {children}
          </main>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default Layout;
