import dayjs from "dayjs";
import Cookies from "js-cookie";
import * as Yup from "yup";

//Getting current user and date
const currentTime = dayjs().format("YYYY-MM-DD");
const userId = Cookies.get("PMUSERNAME");
// validation schema
export const TaskValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, "Minimum 2 letters is required")
    .required("Sub task is required"),
  description: Yup.string()
    .min(10, "Minimum 10 letters is required")
    .required("Task description is required"),
  assignedBy: Yup.string().required("Assignee is required"),
  assignedTo: Yup.string().required("Assigned to is required"),
  startDate: Yup.string().required("Date  today is required"),
  projectId: Yup.string()
    .min(0, "No project with id Zero")
    .required("Project Id is required"),
  taskStatus: Yup.string().required("Task Status is required"),
  taskType: Yup.string().required("Task type is required"),
  priority: Yup.string().required("Priority is required"),
});

// initial values
export const initializeTaskInitialValues = () => {
  return {
    name: "",
    description: "",
    assignedBy: userId,
    assignedTo: "",
    taskStatus: "OPEN",
    taskType: "",
    priority: "LOW",
    projectId: "",
    team: "",
    startDate: currentTime,
  };
};
