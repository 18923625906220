import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthRouteProps } from "../../../Type";
import { useEffect } from "react";
import { AuthChecker } from "../../../Utils/helpers/helpers";

const PublicAuthProvider = ({ loginStatus }: AuthRouteProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    AuthChecker(navigate, pathname);
  }, [loginStatus]);

  return <Outlet />;
};

export default PublicAuthProvider;
