import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthRouteProps } from "../../../Type/index";
import Layout from "../../../Components/Layouts/index";
import Cookies from "js-cookie";

const PrivateAuthProvider = ({ loginStatus }: AuthRouteProps) => {
  const [auth, setAuth] = useState<boolean>(
    !!Cookies.get("PMAUTH_TOKEN" || null)
  );
  const location = useLocation();

  useEffect(() => {
    const authStatus: any | null = Cookies.get("PMAUTH_TOKEN" || null);
    setAuth(authStatus !== null);
  }, [loginStatus]);

  return auth ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
};

export default PrivateAuthProvider;
