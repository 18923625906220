import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PublicAuthProvider from "./Auth/PublicAuthProvider";
import PrivateAuthProvider from "./Auth/PrivateAuth";
import Login from "../Public/Login/login";
import RoleProvider from "./Auth/RoleProvider";
import { USER_ROLES } from "../../Utils/Constants/AuthenticationConstants";
import EmployeeDashboard from "../Private/Dashboard";
import Attendance from "../Private/Timing/Attendance/Attendance";
import ProductListing from "../Private/TaskListings/ProductListing";
import TaskListing from "../Private/TaskListings/TaskListing";
import AllattendanceForAdmin from "../Private/Timing/AllAttendanceForadmin";
import { AnimatePresence } from "framer-motion";

const RoutesHandler = () => {
  return (
    <AnimatePresence>
      <Router>
        <Routes>
          <Route element={<PublicAuthProvider loginStatus={false} />}>
            <Route path="/" element={<Login />}></Route>
          </Route>

          <Route element={<PrivateAuthProvider loginStatus={true} />}>
            {/* Admin  */}
            <Route
              element={
                <RoleProvider Role={USER_ROLES?.ADMIN} loginStatus={true} />
              }
            >
              <Route path="/admin/dashboard" element={<EmployeeDashboard />} />
              <Route path="/admin/timing" element={<Attendance />} />
              <Route
                path="/admin/productlisting"
                element={<ProductListing />}
              />
              <Route path="/admin/tasklisting/:id" element={<TaskListing />} />
              <Route
                path="/admin/allattendance"
                element={<AllattendanceForAdmin />}
              />
            </Route>

            {/* Team Lead  */}
            <Route
              element={
                <RoleProvider Role={USER_ROLES?.TEAMLEAD} loginStatus={true} />
              }
            >
              <Route path="/lead/dashboard" element={<EmployeeDashboard />} />
              <Route path="/lead/timing" element={<Attendance />} />
              <Route path="/lead/productlisting" element={<ProductListing />} />
              <Route path="/lead/tasklisting/:id" element={<TaskListing />} />
            </Route>
            {/* Member */}
            <Route
              element={
                <RoleProvider Role={USER_ROLES?.USER} loginStatus={true} />
              }
            >
              <Route
                path="/employee/dashboard"
                element={<EmployeeDashboard />}
              />
              <Route path="/employee/timing" element={<Attendance />} />
              <Route
                path="/employee/productlisting"
                element={<ProductListing />}
              />
              <Route
                path="/employee/tasklisting/:id"
                element={<TaskListing />}
              />
            </Route>
          </Route>
        </Routes>
      </Router>
    </AnimatePresence>
  );
};

export default RoutesHandler;
