import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";
import { setValidationErrors } from "../../../../Utils/helpers/helpers";

export const TaskListUpdateHandler = (
  values: any,
  actions: any
): Promise<any> => {
  const id = values?.taskId;

  return api
    .put(`/api/tasks/daily-task/${id}/status`, values, false)
    .then(([success, response]: any) => {
      if (success) {
        toast.success("Sub task edited");
      } else {
        toast.error("Error in task editing");
      }
      actions.setSubmitting(false);
      if (response?.errors) {
        setValidationErrors(response?.errors, actions);
      }
      return response;
    })
    .catch((err) => {
      actions.setSubmitting(false);
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          toastId: "Error in editing Task",
        });
      } else {
        toast.error("Unexpected error occurred", {
          toastId: "error-occurred",
        });
      }
      throw err;
    });
};
