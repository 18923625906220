import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler,
  Collapse,
} from "reactstrap";
import { useState } from "react";
import AlertModal from "../../AlertModal/AlertModal";
import ModalContainer from "../../Modal/ModalContainer";
import Cookies from "js-cookie";
import ChangePassword from "../../../Pages/Private/Password";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { MdOutlineAccountCircle } from "react-icons/md";
import IconButton from "@mui/material/IconButton";

const Navigation = () => {
  const url = window.location.pathname;
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(window.location.pathname);
  const role = Cookies.get("PMROLE");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleNavLinkClick = (path: any) => {
    setActiveLink(path);
  };

  const getNavLinks = () => {
    if (role === "ADMIN") {
      return [
        { path: "/admin/dashboard", label: "Desktop" },
        { path: "/admin/productlisting", label: "Projects" },
      ];
    } else if (role === "USER") {
      return [
        { path: "/employee/dashboard", label: "Desktop" },
        { path: "/employee/productlisting", label: "Projects" },
      ];
    } else if (role === "TEAMLEAD") {
      return [
        { path: "/lead/dashboard", label: "Desktop" },
        { path: "/lead/productlisting", label: "Projects" },
      ];
    } else {
      return [];
    }
  };

  return (
    <div
      className="header_background"
      style={{
        minHeight:
          url == "/admin/dashboard"
            ? "22vh"
            : url == "/employee/dashboard"
            ? "22vh"
            : url == "/lead/dashboard"
            ? "22vh"
            : "16vh",
      }}
    >
      <Navbar
        className="header_background px-auto"
        style={{ alignItems: "center" }}
        dark
        expand="lg"
      >
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="d-flex align-items-center " navbar>
            <NavItem>
              <NavLink style={{ fontSize: 25, color: "#FFFFFF" }}>
                Brainiac
              </NavLink>
            </NavItem>
            {getNavLinks()?.map((link, index) => (
              <NavItem key={index + 1}>
                <NavLink
                  href={link?.path}
                  className={activeLink === link.path ? "active" : ""}
                  onClick={() => handleNavLinkClick(link?.path)}
                >
                  {link.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>

          <Nav navbar className="ms-auto">
            {role !== null && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <MdOutlineAccountCircle color="white" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => setIsOpenLogout(true)}>
                    Logout
                  </MenuItem>
                  <MenuItem onClick={() => setIsOpenPassword(true)}>
                    Change Password
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Nav>
        </Collapse>
      </Navbar>

      <div className="container-fluid">
        <Nav navbar>
          {role !== null && (
            <NavItem style={{ display: "flex", alignItems: "center" }}>
              <NavLink>
                {url?.includes("dashboard") ? (
                  <div
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "18px",
                      paddingLeft: 10,
                    }}
                  >
                    <p color="white">Welcome back, {Cookies?.get("NAME")}</p>
                  </div>
                ) : url?.includes("productlisting") ? (
                  <div
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "20px",
                      paddingLeft: 10,
                    }}
                  >
                    <p color="white">Projects</p>
                  </div>
                ) : (
                  <p> Welcome back,</p>
                )}
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </div>

      <ModalContainer
        isOpen={isOpenLogout}
        setIsOpen={setIsOpenLogout}
        title={"Are you sure ?"}
      >
        {" "}
        <AlertModal setIsOpenLogout={setIsOpenLogout} />
      </ModalContainer>

      <ModalContainer
        isOpen={isOpenPassword}
        setIsOpen={setIsOpenPassword}
        title={"Are you sure ?"}
      >
        <ChangePassword setIsOpenLogout={setIsOpenPassword} />
      </ModalContainer>
    </div>
  );
};

export default Navigation;
