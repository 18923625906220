import { Container, Row, Col, Input, Label } from "reactstrap";
import Select from "react-select";
import { WorkArray } from "../../../../Utils/Constants/SelectConstants";
import { EmployeeStatus, getGitStatus } from "../../../../Api/WebsiteApi";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import PresentCounts from "../Counts/PresentCount";
import ProjectsCounts from "../Counts/ProjectsCount";
import Cookies from "js-cookie";
import { gitPush, workStatus } from "../CheckIn&Out/CheckInOutMethods";
import useTagStore from "../../../../Store/store";

const WorkType: React.FC = () => {
  const userId = Cookies.get("PMUSERNAME") || "";
  const { currentDate } = useTagStore();

  //Status of employee
  const { data: AllEmployeeStatus, refetch: AllEmployeeStatusRefetch }: any =
    useQuery({
      queryKey: ["EmployeeStatusC"],
      queryFn: () => EmployeeStatus(userId, currentDate),
    });

  //git status
  const { data: gitStatusData, refetch: gitStatusRefetch } = useQuery({
    queryKey: ["GitStatus"],
    queryFn: () => getGitStatus(userId, currentDate),
    placeholderData: keepPreviousData,
    enabled: Cookies.get("PMROLE") === "USER",
  });

  //Git push
  const handleCheckboxChange = () => {
    gitPush(currentDate, userId, gitStatusRefetch);
  };

  return (
    <>
      {/* Show present counts to admin only and project counts to employee and admin (different in admin and employee)  */}
      {Cookies.get("PMROLE") === "ADMIN" ? <PresentCounts /> : null}
      <ProjectsCounts />

      {/* Only show checkin checkout to employees */}

      {/* Work Mode Selection For Employee */}
      {Cookies.get("PMROLE") !== "ADMIN" ? (
        <Container
          fluid
          style={{ padding: 0, marginTop: 20, marginBottom: 20 }}
        >
          <h5 style={{ textAlign: "start" }} className="tbold">
            Work Type
          </h5>

          <Row className="d-flex " style={{ justifyContent: "space-between" }}>
            <Col sm={3} className="rcorners2 mx-2 my-2">
              <Select
                placeholder="Work or Leave"
                className="black"
                options={WorkArray}
                value={WorkArray.find(
                  (item) => item?.value === AllEmployeeStatus?.status
                )}
                onChange={(selectedOption) => {
                  workStatus(
                    selectedOption?.value,
                    userId,
                    currentDate,
                    AllEmployeeStatusRefetch
                  );
                }}
                name="status"
                isLoading={false}
                loadingMessage={() => "Fetching Work Mode"}
                noOptionsMessage={() => "Work mode appears here"}
                isSearchable={true}
                isClearable
              />
            </Col>
            <Col sm={4} className="mx-2 my-2">
              <Label check className="mx-5">
                Git Pushed
              </Label>
              <Input
                type="checkbox"
                style={{ border: "2px solid #0756ab" }}
                className="align-self-center"
                onChange={handleCheckboxChange}
                checked={gitStatusData?.data?.gitStatus || false}
              />
            </Col>
          </Row>
        </Container>
      ) : null}
    </>
  );
};

export default WorkType;
