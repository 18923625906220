import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Container, Col, Form, Input, Row } from "reactstrap";
import {
  initializeTaskInitialValues,
  TaskValidation,
} from "./TaskCreateMethods/FormikMethods";
import { TaskSubmitHandler } from "./TaskCreateMethods/TaskHandlers";
import TextError from "../../../../Components/Errors/TextErrors";
import CustomButton from "../../../../Components/Button/CustomButton";
import { getAllEmployess, getAllMainTask } from "../../../../Api/WebsiteApi";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  priorityArray,
  statusArray,
  TaskTypeArray,
  ColorMap,
  colorMapPriority,
  colorMapType,
} from "../../../../Utils/Constants/SelectConstants";
import { useParams } from "react-router-dom";
import { TaskProps } from "../../../../Type";
import {
  CustomOptionPriority,
  CustomOptionStatus,
  CustomOptionType,
  CustomSingleValue,
  CustomSingleValuePriority,
  CustomSingleValueType,
  customStyles,
  customStylesPriority,
  customStylesType,
} from "../../../../Utils/helpers/helpers";
import {
  MdAssignment,
  MdOutlineOutlinedFlag,
  MdOutlinePeopleOutline,
  MdAssessment,
} from "react-icons/md";

const Task = ({ refetch, setIsopen, edit, data }: TaskProps) => {
  const { id } = useParams();
  const [rows, setRows] = useState<number>(1);
  const [TaskInitialValues, setTaskInitialvalues] = useState(() =>
    initializeTaskInitialValues()
  );

  useEffect(() => {
    if (edit) {
      setTaskInitialvalues((prevValues) => ({
        ...prevValues,
        ...data,
      }));
    }

    return () => {};
  }, [edit]);

  //Api for All Main Tasks
  const { data: fullMainTaskData }: any = useQuery({
    queryKey: ["MainTask"],
    queryFn: () => getAllMainTask(),
    placeholderData: keepPreviousData,
  });

  //Api for all Employees
  const { data: allEmployeeData }: any = useQuery({
    queryKey: ["AllEmployee"],
    queryFn: () => getAllEmployess(),
    placeholderData: keepPreviousData,
  });

  // For fetching all details from main task to fill in other inputs
  const findTaskType = (mainTaskId: any) => {
    const task = fullMainTaskData?.find(
      (itm: any) => itm?.mainTaskId === mainTaskId
    );
    return task ? task : "";
  };

  const increase = () => {
    setRows(10);
  };
  return (
    <Container>
      <Row className="justify-content-center align-items-center ">
        <Col sm={12} style={{ padding: 0 }}>
          <div className="newProject">
            <Formik
              validationSchema={TaskValidation}
              initialValues={TaskInitialValues}
              enableReinitialize
              onSubmit={(values: any, actions: any) =>
                TaskSubmitHandler(values, actions, refetch, setIsopen)
              }
            >
              {({
                values,
                isSubmitting,
                handleChange,
                setFieldValue,
                handleSubmit,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="g-3 white">
                    <Col md={12}>
                      <Input
                        type="text"
                        name="name"
                        placeholder="Task name"
                        value={values?.name}
                        aria-describedby="newpass"
                        onChange={handleChange}
                        required
                        style={{ border: 0 }}
                      />
                      <ErrorMessage name="name" component={TextError} />
                    </Col>

                    {/* Sub Task description input */}
                    <Col md={12}>
                      <Input
                        type="textarea"
                        name="description"
                        placeholder="📄 add description"
                        value={values?.description}
                        aria-describedby="newpass"
                        onChange={handleChange}
                        required
                        onClick={() => increase()}
                        rows={rows}
                        style={{ border: 0 }}
                      />
                      <ErrorMessage name="description" component={TextError} />
                    </Col>
                    <Col lg={6}>
                      <Select
                        menuPosition="fixed"
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MdOutlineOutlinedFlag
                              style={{ marginRight: "8px" }}
                            />
                            <p style={{ margin: 0 }}>Select Priority</p>
                          </div>
                        }
                        options={priorityArray}
                        value={
                          priorityArray?.find(
                            (itm: any) => itm?.value === values?.priority
                          ) || ""
                        }
                        onChange={(e: any) =>
                          setFieldValue("priority", e?.value)
                        }
                        name="priority"
                        isLoading={false}
                        loadingMessage={() => "Fetching Priority"}
                        noOptionsMessage={() => "Priorities appear here"}
                        isSearchable={true}
                        isClearable
                        required
                        className="black"
                        components={{
                          Option: CustomOptionPriority,
                          SingleValue: CustomSingleValuePriority,
                        }}
                        styles={customStylesPriority(colorMapPriority)}
                      />
                      <ErrorMessage name="priority" component={TextError} />
                    </Col>
                    <Col lg={6}>
                      <Select
                        menuPosition="fixed"
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MdAssignment style={{ marginRight: "8px" }} />
                            <p style={{ margin: 0 }}>Select status</p>
                          </div>
                        }
                        options={statusArray}
                        value={
                          statusArray?.find(
                            (itm: any) => itm?.value === values?.taskStatus
                          ) || ""
                        }
                        onChange={(e: any) =>
                          setFieldValue("taskStatus", e?.value)
                        }
                        name="taskStatus"
                        isLoading={false}
                        loadingMessage={() => "Fetching Task Status"}
                        noOptionsMessage={() => "Task Status appears here"}
                        isSearchable={true}
                        isClearable
                        required
                        className="black"
                        styles={customStyles(ColorMap)}
                        components={{
                          Option: CustomOptionStatus,
                          SingleValue: CustomSingleValue,
                        }}
                      />
                      <ErrorMessage name="taskStatus" component={TextError} />
                    </Col>

                    <Col lg={5}>
                      <Select
                        menuPosition="fixed"
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MdOutlinePeopleOutline
                              style={{ marginRight: "8px" }}
                            />
                            <p style={{ margin: 0 }}>Assigned</p>
                          </div>
                        }
                        options={allEmployeeData}
                        value={
                          allEmployeeData?.find(
                            (itm: any) => itm?.value == values?.assignedTo
                          ) || ""
                        }
                        onChange={(e: any) => {
                          setFieldValue("assignedTo", e?.value);
                          const selectedTaskType = findTaskType(e?.value);
                          setFieldValue("projectId", id);
                          setFieldValue("team", selectedTaskType?.teamId);
                        }}
                        name="assignedTo"
                        isLoading={false}
                        loadingMessage={() => "Fetching Assignee"}
                        noOptionsMessage={() => "Assignee appears here"}
                        isSearchable={true}
                        isClearable
                        required
                        className="black"
                      />
                      <ErrorMessage name="assignedTo" component={TextError} />
                    </Col>

                    {/* Task Status dropdown for admin */}

                    {/* Sub Task description input */}
                    <Col lg={4}>
                      <Select
                        menuPosition="fixed"
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MdAssessment style={{ marginRight: "8px" }} />
                            <p style={{ margin: 0 }}>Type</p>
                          </div>
                        }
                        options={TaskTypeArray}
                        value={
                          TaskTypeArray?.find(
                            (itm: any) => itm?.value === values?.taskType
                          ) || ""
                        }
                        onChange={(e: any) =>
                          setFieldValue("taskType", e?.value)
                        }
                        name="taskType"
                        isLoading={false}
                        loadingMessage={() => "Fetching taskType"}
                        noOptionsMessage={() => "taskType appear here"}
                        isSearchable={true}
                        isClearable
                        required
                        className="black"
                        components={{
                          Option: CustomOptionType,
                          SingleValue: CustomSingleValueType,
                        }}
                        styles={customStylesType(colorMapType)}
                      />
                      <ErrorMessage name="taskType" component={TextError} />
                    </Col>

                    {/* Button */}
                    <Col md={3}>
                      <CustomButton
                        isLoading={isSubmitting}
                        label="Submit"
                        color="primary"
                        className="sm submit-btn w-100"
                      />
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Task;
