import { toast } from "react-toastify";
import { api } from "../../../../Api/Api";
import {
  AuthChecker,
  setValidationErrors,
} from "../../../../Utils/helpers/helpers";
import Cookies from "js-cookie";

export const LoginSubmitHandler = (
  values: any,
  actions: any,
  navigate: any
) => {
  api
    .post("/api/auth/login", values, false)
    .then(async function ([success, response]: any) {
      if (response?.access_token) {
        const TOKEN = response?.access_token;
        const ROLE = response?.role[0];
        const USER = response?.username;
        const Name = response?.name;
        //Setting Token
        Cookies.set("PMAUTH_TOKEN", TOKEN);
        //Setting Role
        Cookies?.set(
          "PMROLE",
          ROLE === "ADMIN"
            ? "ADMIN"
            : ROLE === "USER"
            ? "USER"
            : ROLE === "TEAMLEAD"
            ? "TEAMLEAD"
            : ""
        );
        //Setting username
        Cookies.set("PMUSERNAME", USER);
        Cookies.set("NAME", Name);
        AuthChecker(navigate, "/");
      }
      if (response?.errors) {
        actions.setSubmitting(false);
        setValidationErrors(response?.errors, actions);
      }
    })
    .catch((err) => {
      actions.setSubmitting(false);
      if (err.response?.data?.error) {
        toast.error(err.response?.data?.error?.msg, {
          toastId: "Invalid-login-details",
        });
      } else {
        toast?.error("Unexpected error occurred", {
          toastId: "error-occurred",
        });
      }
    });
};
