import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { CheckInHandler, CheckOutHandler } from "./CheckInOutMethods";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  getAllTimingForSingleEmployee,
  getEmployeeStatus,
  getWorkDuration,
} from "../../../../Api/WebsiteApi";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { workDurationType } from "../../../../Type";
import useTagStore from "../../../../Store/store";

const CheckInOut: React.FC<any> = (props: any) => {
  const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);
  const [isCheckedOut, setIsCheckedOut] = useState<boolean>(false);
  const [checkInTime, setCheckInTime] = useState<string>("");
  const [checkOutTime, setCheckOutTime] = useState<string>("");
  const [totalDuration, setTotalDuration] = useState<string>("");
  const currentDateTime = dayjs().format("YYYY-MM-DDTHH:mm:ss");
  const userId = Cookies.get("PMUSERNAME") || "";
  const { currentDate } = useTagStore();

  const { data: workDuration, refetch: workDurationRefetch } =
    useQuery<workDurationType>({
      queryKey: ["workDuration", userId, currentDate],
      queryFn: () => getWorkDuration(userId, currentDate),
      enabled: isCheckedOut,
    });

  const { data: employeeStatusData, refetch: employeeStatusRefetch } = useQuery(
    {
      queryKey: ["employeeStatus", userId],
      queryFn: () => getEmployeeStatus(userId),
      placeholderData: keepPreviousData,
    }
  );

  const { data: timeStatusData, refetch: timeRefetch } = useQuery({
    queryKey: ["employeetiming", userId],
    queryFn: () => getAllTimingForSingleEmployee(userId),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (timeStatusData?.length > 0) {
      setCheckInTime(
        dayjs(timeStatusData?.[0]?.checkInInstance)?.format("hh:mm A")
      );
      setCheckOutTime(
        dayjs(timeStatusData?.[0]?.checkOutInstance)?.format("hh:mm A")
      );
    }
  }, [timeStatusData]);

  const handleCheckIn = async () => {
    await CheckInHandler(
      setIsCheckedIn,
      setIsCheckedOut,
      userId,
      currentDateTime
    );
    timeRefetch();
    employeeStatusRefetch();
    props?.refetch();
  };

  const handleCheckOut = async () => {
    await CheckOutHandler(
      setIsCheckedOut,
      setIsCheckedIn,
      currentDateTime,
      userId
    );
    workDurationRefetch();
    timeRefetch();
    employeeStatusRefetch();
    props?.refetch();
    setTotalDuration(workDuration?.totalHours || "0");
  };

  return (
    <>
      {Cookies.get("PMROLE") === "ADMIN" ? null : (
        <>
          <h5 style={{ textAlign: "start", margin: 0 }} className="tbold">
            Attendance
          </h5>

          <Row className="my-4 mx-0 d-flex">
            {employeeStatusData?.status === "Checked Out" && (
              <Col md={3} sm={12}>
                <button
                  onClick={handleCheckIn}
                  className="button buttonColor buttonStyle w-100"
                >
                  CheckIn
                </button>
              </Col>
            )}

            {employeeStatusData?.status === "Checked In" && (
              <Col md={3}>
                <button
                  onClick={handleCheckOut}
                  className="button buttonColor buttonStyle w-100"
                >
                  CheckOut
                </button>
              </Col>
            )}

            <Col sm={6} md={3}>
              <button className="button buttonColor buttonStyle w-100">
                {workDuration?.totalHours
                  ? `${workDuration?.totalHours}.${workDuration?.totalMinutes} Hrs`
                  : workDuration?.totalMinutes
                  ? `${workDuration.totalMinutes} Min`
                  : "0 Min"}
              </button>
            </Col>

            <Col sm={6} md={6}>
              <button className="button buttonColor buttonStyle w-100">
                {checkInTime ? checkInTime : "0"} -{" "}
                {timeStatusData?.[0]?.checkOutInstance
                  ? checkOutTime
                    ? checkOutTime
                    : "0"
                  : "N/A"}
              </button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CheckInOut;
