import { ErrorMessage, Formik } from "formik";
import { ChangeEvent, useEffect, useState } from "react";
import Select from "react-select";
import { Container, Col, Form, Input, Row } from "reactstrap";
import TextError from "../../../../Components/Errors/TextErrors";
import CustomButton from "../../../../Components/Button/CustomButton";
import { getAllEmployess, getAllTask } from "../../../../Api/WebsiteApi";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  priorityArray,
  statusArray,
  TaskTypeArray,
  ColorMap,
  colorMapPriority,
  colorMapType,
} from "../../../../Utils/Constants/SelectConstants";
import Cookies from "js-cookie";
import { DailyTaskSubmitHandler } from "./DailyTaskMethods/DailyTaskHandlers";
import {
  DailyTaskValidation,
  initializeDailyTaskInitialValues,
} from "./DailyTaskMethods/FormikMethods";
import {
  CustomOptionPriority,
  CustomOptionStatus,
  CustomOptionType,
  CustomSingleValue,
  CustomSingleValuePriority,
  CustomSingleValueType,
  customStyles,
  customStylesPriority,
  customStylesType,
} from "../../../../Utils/helpers/helpers";
import {
  MdAssessment,
  MdOutlineOutlinedFlag,
  MdOutlinePeopleOutline,
} from "react-icons/md";
import useTagStore from "../../../../Store/store";

const DailyTask = ({
  fullDailyTaskRefetch,
  setIsOpen,
  main,
  data,
  edit,
}: any) => {
  const [rows, setRows] = useState<number>(1);
  const { currentDate } = useTagStore();
  const userId = Cookies.get("PMUSERNAME");
  const url = window?.location?.pathname;
  const [TaskInitialValues, setTaskInitialValues] = useState(() =>
    initializeDailyTaskInitialValues()
  );

  const { data: allEmployeeData }: any = useQuery({
    queryKey: ["AllEmployee"],
    queryFn: () => getAllEmployess(),
    placeholderData: keepPreviousData,
  });

  const { data: fullTaskData }: any = useQuery({
    queryKey: ["Taskforproject"],
    queryFn: () => getAllTask(),
    placeholderData: keepPreviousData,
  });

  const mainTasks = fullTaskData?.map((taskData: any) => ({
    value: taskData?.id,
    label: taskData?.name,
  }));

  useEffect(() => {
    if (edit) {
      setTaskInitialValues({
        taskId: data?.taskId || "",
        name: data?.name,
        description: data?.description,
        assignedBy: data?.assignedBy,
        assignedTo: main?.assignedTo,
        date: data?.date,
        projectId: main?.projectId,
        team: main?.team,
        status: data?.status,
        priority: data?.priority,
        timeTaken: data?.timeTaken,
        taskType: data?.taskType,
      });
    } else {
      setTaskInitialValues({
        taskId: main?.id || "",
        name: "",
        description: "",
        assignedBy: userId,
        assignedTo: main?.assignedTo || "",
        date: currentDate,
        projectId: main?.projectId || "",
        team: main?.team || "",
        status: main?.taskStatus || "",
        priority: main?.priority || "",
        timeTaken: main?.timeTaken || "",
        taskType: "",
      });
    }
  }, [main]);

  const increase = () => {
    setRows(10);
  };

  return (
    <Container style={{ margin: 0, padding: 10 }}>
      <Row style={{ margin: 0, padding: 0 }}>
        <Col sm={12}>
          <Formik
            validationSchema={DailyTaskValidation}
            initialValues={TaskInitialValues}
            enableReinitialize
            onSubmit={(values, actions) => {
              actions.setFieldValue("taskId", main?.id);
              actions.setFieldValue("taskType", main?.taskType || "");
              actions.setFieldValue("projectId", main?.projectId || "");
              actions.setFieldValue("team", main?.teamId || "");
              actions.setFieldValue("assignedBy", userId || "");
              actions.setFieldValue("date", currentDate || "");

              if (Cookies.get("PMROLE") == "USER" && userId) {
                actions.setFieldValue("status", "IN_PROGRESS");
                actions.setFieldValue("priority", "LOW");
                actions.setFieldValue("taskType", "");
              }

              DailyTaskSubmitHandler(
                values,
                actions,
                fullDailyTaskRefetch,
                setIsOpen
              );
            }}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="g-4 white my-2">
                  {/* Main Task dropdown */}
                  <Col>
                    <Select
                      placeholder="Task"
                      options={mainTasks || []}
                      value={
                        mainTasks?.find(
                          (itm: any) => itm?.value === values.taskId
                        ) || null
                      }
                      onChange={(e: any) => {
                        setFieldValue("taskId", e?.value);
                      }}
                      name="taskId"
                      isLoading={false}
                      loadingMessage={() => "Fetching Main Task"}
                      noOptionsMessage={() => "Main Task appears here"}
                      isSearchable={true}
                      isClearable
                      required
                      className="black"
                      isDisabled={url.includes("/tasklisting/")}
                    />
                    <ErrorMessage name="taskId" component={TextError} />
                  </Col>
                  {/* Priority only for admin */}
                  {Cookies.get("PMROLE") === "ADMIN" && (
                    <Col md={6}>
                      <Select
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MdOutlineOutlinedFlag
                              style={{ marginRight: "8px" }}
                            />
                            <p style={{ margin: 0 }}>Select Priority</p>
                          </div>
                        }
                        options={priorityArray}
                        value={
                          priorityArray?.find(
                            (itm: any) => itm?.value === values?.priority
                          ) || null
                        }
                        onChange={(e: any) =>
                          setFieldValue("priority", e?.value)
                        }
                        name="priority"
                        isLoading={false}
                        loadingMessage={() => "Fetching Priority"}
                        noOptionsMessage={() => "Priorities appear here"}
                        isSearchable={true}
                        isClearable
                        required
                        className="black"
                        components={{
                          Option: CustomOptionPriority,
                          SingleValue: CustomSingleValuePriority,
                        }}
                        styles={customStylesPriority(colorMapPriority)}
                      />
                      <ErrorMessage name="priority" component={TextError} />
                    </Col>
                  )}
                  {/* Task Status dropdown for admin */}
                  <Col md={6}>
                    <Select
                      placeholder="Task status"
                      options={statusArray}
                      value={
                        statusArray?.find(
                          (itm: any) => itm?.value === values?.status
                        ) || null
                      }
                      onChange={(e: any) => setFieldValue("status", e?.value)}
                      name="status"
                      isLoading={false}
                      loadingMessage={() => "Fetching Task Status"}
                      noOptionsMessage={() => "Task Status appears here"}
                      isSearchable={true}
                      isClearable
                      required
                      className="black"
                      styles={customStyles(ColorMap)}
                      components={{
                        Option: CustomOptionStatus,
                        SingleValue: CustomSingleValue,
                      }}
                    />
                    <ErrorMessage name="status" component={TextError} />
                  </Col>
                  {/* Assigned to dropdown only for admin */}
                  {Cookies.get("PMROLE") === "ADMIN" ? (
                    <Col md={6}>
                      <Select
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <MdOutlinePeopleOutline
                              style={{ marginRight: "8px" }}
                            />
                            <p style={{ margin: 0 }}>Assigned</p>
                          </div>
                        }
                        options={allEmployeeData}
                        value={
                          allEmployeeData?.find(
                            (itm: any) => itm?.value === values.assignedTo
                          ) || null
                        }
                        onChange={(e: any) =>
                          setFieldValue("assignedTo", e?.value)
                        }
                        name="assignedTo"
                        isLoading={false}
                        loadingMessage={() => "Fetching Assignee"}
                        noOptionsMessage={() => "Assignee appears here"}
                        isSearchable={true}
                        isClearable
                        required
                        className="black"
                      />
                      <ErrorMessage name="assignedTo" component={TextError} />
                    </Col>
                  ) : (
                    ""
                  )}
                  {/* Sub task name input */}
                  <Col md={6}>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Task name"
                      value={values?.name}
                      aria-describedby="newpass"
                      onChange={handleChange}
                      required
                      style={{ border: 0 }}
                    />
                    <ErrorMessage name="name" component={TextError} />
                  </Col>
                  <Col md={6}>
                    <Select
                      menuPosition="fixed"
                      placeholder={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <MdAssessment style={{ marginRight: "8px" }} />
                          <p style={{ margin: 0 }}>Type</p>
                        </div>
                      }
                      options={TaskTypeArray}
                      value={
                        TaskTypeArray?.find(
                          (itm: any) => itm?.value === values?.taskType
                        ) || null
                      }
                      onChange={(e: any) => setFieldValue("taskType", e?.value)}
                      name="taskType"
                      isLoading={false}
                      loadingMessage={() => "Fetching Task type"}
                      noOptionsMessage={() => "Task type appears here"}
                      isSearchable={true}
                      isClearable
                      required
                      className="black"
                      components={{
                        Option: CustomOptionType,
                        SingleValue: CustomSingleValueType,
                      }}
                      styles={customStylesType(colorMapType)}
                    />
                    <ErrorMessage name="taskType" component={TextError} />
                  </Col>
                  {/* Daily Task description input */}
                  <Col md={12}>
                    <Input
                      type="textarea"
                      name="description"
                      placeholder="📄 add description"
                      value={values?.description}
                      aria-describedby="newpass"
                      onChange={handleChange}
                      required
                      style={{ border: 0 }}
                      rows={rows}
                      onClick={() => increase()}
                    />
                    <ErrorMessage name="description" component={TextError} />
                  </Col>

                  {/* Time taken input */}
                  <Col sm={6} className="d-flex justify-content-between">
                    <Input
                      type="text"
                      id="timeTaken"
                      name="timeTaken"
                      value={values?.timeTaken}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        let value = e.target.value.replace(/[^0-9]/g, "");
                        if (value.length > 4) {
                          value = value.slice(0, 4);
                        }
                        if (value.length > 2) {
                          value = value.slice(0, 2) + ":" + value.slice(2);
                        }
                        setFieldValue("timeTaken", value);
                      }}
                      placeholder="🕛 Time taken in HH:MM"
                      required
                      maxLength={5}
                    />
                    <ErrorMessage name="timeTaken" component={TextError} />
                  </Col>

                  {/* Task Status dropdown for admin */}
                  {/* Button */}
                  <Col md={6}>
                    <CustomButton
                      isLoading={isSubmitting}
                      label="Submit"
                      color="primary"
                      className="sm my-2 submit-btn w-100"
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default DailyTask;
