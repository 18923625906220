import Cookies from "js-cookie";

const getData = () => {
  if (typeof window !== "undefined") {
    let token = Cookies.get("PMAUTH_TOKEN");
    return token;
  }
};
const clearData = () => {
  if (typeof window !== "undefined") {
    let token = Cookies.remove("PMAUTH_TOKEN");
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      // Extract cookie name
      const cookie = cookies[i].split("=");
      const name = cookie[0].trim();

      // Set cookie with expired date
      document.cookie =
        name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    return token;
  }
};
export { getData, clearData };
