import { MdOutlineInsertDriveFile } from "react-icons/md";

type StatusType = {
  value: string;
};

const STATUS: Record<string, StatusType> = {
  Open: {
    value: "OPEN",
  },
  InProgress: {
    value: "IN_PROGRESS",
  },
  Completed: {
    value: "COMPLETED",
  },
  Forged: {
    value: "FORGED",
  },
};

const statusArray = Object.entries(STATUS).map(([label, { value }]) => ({
  value,
  label,
}));

const PRIORITY = {
  NORMAL: { value: "LOW" },
  HIGH: { value: "MEDIUM" },
  URGENT: { value: "HIGH" },
};
const priorityArray = Object.entries(PRIORITY).map(([label, { value }]) => ({
  value,
  label,
}));

const TaskType = {
  TESTING: { value: "TESTING" },
  DEV: { value: "DEV" },
  BUG_FIX: { value: "BUG_FIX" },
};

const TaskTypeArray = Object.entries(TaskType).map(([label, { value }]) => ({
  value,
  label,
}));

const Work = {
  PRESENT: "PRESENT",
  WFH: "WFH",
  LEAVE: "LEAVE",
  HALFDAYLEAVE: "HALF_DAY_LEAVE",
  FIELDWORK: "FIELD_WORK",
};

const WorkArray = Object.entries(Work).map(([label, value]) => ({
  value,
  label,
}));

const ColorMap = {
  OPEN: "blue",
  COMPLETED: "green",
  IN_PROGRESS: "orange",
  FORGED: "red",
};

const colorMapPriority = {
  LOW: "blue",
  MEDIUM: "orange",
  HIGH: "red",
};

const colorMapType = {
  TESTING: "blue",
  DEV: "green",
  BUG_FIX: "red",
};

export {
  statusArray,
  priorityArray,
  TaskTypeArray,
  WorkArray,
  ColorMap,
  colorMapPriority,
  colorMapType,
};
