import { Container, Col, Row } from "reactstrap";
import {
  getAllEmployeeWorkCount,
  getEmployeeWorkCount,
} from "../../../../Api/WebsiteApi";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useState } from "react";
import {
  AllEmployeeWorkCountType,
  SingleEmployeeWorkCount,
} from "../../../../Type";
import {
  MdOutlineInsertDriveFile,
  MdOutlineOfflinePin,
  MdContentPaste,
  MdDoNotDisturbAlt,
} from "react-icons/md";
const ProjectsCounts: React.FC = () => {
  const userId = Cookies.get("PMUSERNAME") ?? "";
  const [activeStatus, setActiveStatus] = useState<string>("");
  const userOrNot = Cookies?.get("PMROLE") !== "ADMIN";
  //Work Duration Time Querry
  const { data: singleEmployeeWorkCount } = useQuery<SingleEmployeeWorkCount>({
    queryKey: ["EmployeeWorkCount"],
    queryFn: () => getEmployeeWorkCount(userId),
    placeholderData: keepPreviousData,
    enabled: userOrNot,
  });

  const { data: AllEmployeeWorkCount } = useQuery<AllEmployeeWorkCountType>({
    queryKey: ["EmployeeAttendanceC"],
    queryFn: () => getAllEmployeeWorkCount(),
    placeholderData: keepPreviousData,
    enabled: !userOrNot,
  });

  return (
    <Container fluid style={{ padding: 0 }}>
      <h5 style={{ margin: 0, padding: 0, marginBottom: 8 }} className="tbold">
        Task counts
      </h5>
      <Row className="my-4">
        <Col
          xs={3}
          className={`forflexattendance ${
            activeStatus === "LEAVE" ? "active" : ""
          }`}
        >
          {" "}
          <div className="iconsinattendacedaily">
            <MdOutlineInsertDriveFile size={25} className="me-1" />
            <p style={{ margin: 0 }}>
              {" "}
              {Cookies.get("PMROLE") === "ADMIN"
                ? AllEmployeeWorkCount?.OPEN
                : singleEmployeeWorkCount?.OPEN}
            </p>
          </div>
          <div>
            <p>Open</p>
          </div>
        </Col>
        <Col
          xs={3}
          className={`forflexattendance ${
            activeStatus === "PRESENT" ? "active" : ""
          } `}
        >
          <div className="iconsinattendacedaily">
            <MdOutlineOfflinePin size={25} className="me-1" />{" "}
            <p style={{ margin: 0 }}>
              {" "}
              {Cookies.get("PMROLE") === "ADMIN"
                ? AllEmployeeWorkCount?.COMPLETED
                : singleEmployeeWorkCount?.COMPLETED}
            </p>
          </div>
          <div>Completed</div>
        </Col>
        <Col
          xs={3}
          className={`forflexattendance ${
            activeStatus === "LEAVE" ? "active" : ""
          }`}
        >
          {" "}
          <div className="iconsinattendacedaily">
            <MdContentPaste size={25} className="me-1" />
            <p style={{ margin: 0 }}>
              {" "}
              {Cookies.get("PMROLE") === "ADMIN"
                ? AllEmployeeWorkCount?.IN_PROGRESS
                : singleEmployeeWorkCount?.IN_PROGRESS}
            </p>
          </div>
          <div>InProgress</div>
        </Col>
        <Col
          xs={3}
          className={`forflexattendance ${
            activeStatus === "SITE" ? "active" : ""
          }`}
        >
          {" "}
          <div className="iconsinattendacedaily">
            {" "}
            <MdDoNotDisturbAlt size={25} className="me-1" />
            <p style={{ margin: 0 }}>{singleEmployeeWorkCount?.FORGED}</p>
          </div>
          <div>Forged</div>
        </Col>{" "}
      </Row>
      <Col
        sm={12}
        className=" justify-content-center align-items-center mt-4"
        style={{
          borderRadius: "10px",
          textAlign: "center",
        }}
      ></Col>

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 0,
        }}
        className="my-4"
      ></Row>
    </Container>
  );
};

export default ProjectsCounts;
