import React, { Component, ErrorInfo, ReactNode } from "react";

// Define the types for the props and state
interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

// ErrorBoundary component in TypeScript
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // This lifecycle method is used to handle errors in the component tree
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // This lifecycle method is used to log error details
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    logErrorToMyService(error, errorInfo);
  }

  // Render method to display fallback UI if there is an error
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

// Example function to log errors to a service
function logErrorToMyService(error: Error, errorInfo: ErrorInfo) {
  // Implement your error logging logic here
  console.error("Error logged:", error, errorInfo);
}

export default ErrorBoundary;
