import { useEffect, useState } from "react";
import {
  ProjectValidation,
  initializeProjectInitialValues,
} from "./ProjectCreateMethods/FormikMethods";
import { ProjectSubmitHandler } from "./ProjectCreateMethods/ProjectCreateHandlers";
import { Formik, ErrorMessage } from "formik";
import Select from "react-select";
import { Form, Container, Row, Col, Input } from "reactstrap";
import CustomButton from "../../../../Components/Button/CustomButton";
import TextError from "../../../../Components/Errors/TextErrors";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { getAllTeam } from "../../../../Api/WebsiteApi";
import {
  ColorMap,
  statusArray,
} from "../../../../Utils/Constants/SelectConstants";
import {
  CustomOptionStatus,
  CustomSingleValue,
  customStyles,
} from "../../../../Utils/helpers/helpers";
import { MdOutlinePeopleOutline } from "react-icons/md";
import { TeamsType } from "../../../../Type";

const Project: React.FC<any> = ({
  id,
  edit,
  data,
  refetch,
  setIsopen,
}: any) => {
  const [rows, setRows] = useState<number>(1);
  //Adding current date to initial Values
  const [ProjectInitialValues, setProjectInitialvalues] = useState(() => ({
    ...initializeProjectInitialValues(),
  }));

  useEffect(() => {
    if (edit) {
      setProjectInitialvalues((prevValues) => ({
        ...prevValues,
        ...data,
      }));
    }
  }, [edit]);

  const { data: allTeamsData }: any = useQuery<TeamsType>({
    queryKey: ["AllTeams"],
    queryFn: () => getAllTeam(),
    placeholderData: keepPreviousData,
  });

  const increase = () => {
    setRows(10);
  };

  return (
    <Container className="">
      {/* Subtask component */}
      <Row className="justify-content-center align-items-center">
        <Col sm={12} style={{ padding: 0 }}>
          <div className="newProject">
            {/* Task form inputs */}
            <Formik
              validationSchema={ProjectValidation}
              initialValues={ProjectInitialValues}
              enableReinitialize
              onSubmit={(values: any, actions: any) =>
                ProjectSubmitHandler(values, actions, refetch, setIsopen)
              }
            >
              {({
                values,
                isSubmitting,
                handleChange,
                setFieldValue,
                handleSubmit,
              }: any) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <Row className="g-4">
                      {/* Task name input */}
                      <Col md={12}>
                        <Input
                          type="text"
                          name="projectName"
                          placeholder="Project Name"
                          value={values?.projectName}
                          aria-describedby="newpass"
                          onChange={handleChange}
                          style={{ border: 0 }}
                          required
                        />
                        <ErrorMessage
                          name="projectName"
                          component={TextError}
                        />
                      </Col>

                      {/* Task description name input */}
                      <Col
                        lg={12}
                        className="d-flex align-items-center justify-content-center"
                        style={{ gap: "10px" }}
                      >
                        <Input
                          type="textarea"
                          name="projectDescription"
                          placeholder="📄 add description"
                          value={values?.projectDescription}
                          aria-describedby="newpass"
                          onChange={handleChange}
                          onClick={() => increase()}
                          rows={rows}
                          style={{ border: 0, flex: 1 }}
                          required
                        />

                        <ErrorMessage
                          name="projectDescription"
                          component={TextError}
                        />
                      </Col>

                      {/* Team name dropdown */}
                      <Col lg={4}>
                        <Select
                          menuPosition="fixed"
                          placeholder={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <MdOutlinePeopleOutline
                                style={{ marginRight: "8px" }}
                              />
                              <p style={{ margin: 0 }}>Team</p>
                            </div>
                          }
                          options={allTeamsData}
                          value={
                            allTeamsData?.filter(
                              (itm: any) => itm?.value == values?.teamId
                            ) || ""
                          }
                          onChange={(e: any) => {
                            setFieldValue("productId", id);
                            setFieldValue("teamId", e?.value);
                          }}
                          name="teamId"
                          isLoading={false}
                          loadingMessage={() => "Fetching Teams"}
                          noOptionsMessage={() => "Teams appears here"}
                          isSearchable={true}
                          isClearable
                          required
                          className="black"
                        />
                        <ErrorMessage name="teamId" component={TextError} />
                      </Col>

                      {/* Task type dropdown */}
                      <Col lg={5}>
                        <Select
                          menuPosition="fixed"
                          placeholder="Status"
                          options={statusArray}
                          value={
                            statusArray?.filter(
                              (itm: any) => itm?.value === values?.status
                            ) || ""
                          }
                          onChange={(e: any) => {
                            setFieldValue("status", e?.value);
                          }}
                          components={{
                            Option: CustomOptionStatus,
                            SingleValue: CustomSingleValue,
                          }}
                          name="status"
                          isLoading={false}
                          loadingMessage={() => "Fetching Task Status"}
                          noOptionsMessage={() => "Task status appears here"}
                          isSearchable={true}
                          required
                          className="black"
                          styles={customStyles(ColorMap)}
                        />
                        <ErrorMessage name="status" component={TextError} />
                      </Col>

                      <Col md={3}>
                        <CustomButton
                          isLoading={isSubmitting}
                          label="Submit"
                          color="primary"
                          className="sm submit-btn w-100"
                        />
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Project;
