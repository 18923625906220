import { Row, Col, Container } from "reactstrap";
import Attendance from "../Timing/Attendance/Attendance";
import ProductStatus from "../TaskListings";
import Cookies from "js-cookie";
import ConductMeeting from "../TaskListings/ConductMeeting";

const EmployeeDashboard: React.FC = () => {
  // For adjusting margin for header style in pages
  const url = window?.location?.pathname;

  return (
    <Container
      fluid
      style={{
        marginTop:
          url === "/admin/dashboard" || "/employee/dashboard" ? "-3%" : 0,
      }}
    >
      <Row className="forDashboardFlex">
        {/* Checkin/Out and attendance list for employee ,for admin */}
        <Col md={4} sm={12}>
          <Attendance />
        </Col>
        {/* Conduct Meeting only for admins  Products for Users*/}
        {Cookies?.get("PMROLE") === "ADMIN" ? (
          <Col md={8} sm={12}>
            <ConductMeeting />
          </Col>
        ) : (
          <Col md={8} sm={12}>
            <ProductStatus />
          </Col>
        )}{" "}
      </Row>
    </Container>
  );
};

export default EmployeeDashboard;
