import * as Yup from "yup";
import { PasswordSchema } from "../../../../Utils/Formik/FormikCommonValidationSchemas";

// validation schema
export const LoginValidation = Yup.object().shape({
  username: Yup.string().max(10, "Username must not exceed 10 characters").required("Field is required").nullable(),
  password: PasswordSchema.required("Password is required"),
});

// initial values
export const initializeLoginInitialValues = () => {
  return {
    username: "",
    password: "",
  };
};
