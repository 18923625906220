import dayjs from "dayjs";
import { create } from "zustand";
interface TagStore {
  selectedTagId: string;
  setSelectedTagId: (tagId: string) => void;
  projectId: string;
  setprojectId: (projectId: string) => void;
  searchDataForProject: string;
  setSearchDataForProject: (searchDataForProject: string) => void;
  currentDate: string;
  hasRun: boolean;
  setHasRun: (hasRun: boolean) => void;
}

const useTagStore = create<TagStore>((set) => ({
  selectedTagId: "Vetto",
  projectId: "",
  searchDataForProject: "",
  currentDate: dayjs().format("YYYY-MM-DD"),
  hasRun: false,
  setHasRun: (status) => set({ hasRun: status }),
  setprojectId: (projectId: string) => set({ projectId: projectId }),
  setSelectedTagId: (tagId) => set({ selectedTagId: tagId }),
  setSearchDataForProject: (searchDataForProject) =>
    set({ searchDataForProject: searchDataForProject }),
}));

export default useTagStore;
