import React, { useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Col, Container, Row, Table } from "reactstrap";
import {
  getIndividualMainTaskByprojectId,
  getProjectByprojectId,
} from "../../../../Api/WebsiteApi";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import SubTask from "../../TaskCreations/Task";
import { useNavigate, useParams } from "react-router";
import {
  MdOutlineStickyNote2,
  MdEdit,
  MdDeleteOutline,
  MdArrowBack,
} from "react-icons/md";
import dayjs from "dayjs";
import DailyTaskListing from "../DailyTaskListing";
import { ProjectInTask, Task } from "../../../../Type/index";
import CustomSpinner from "../../../../Components/Button/CustomSpinner";
import Cookies from "js-cookie";
import {
  getFirstTwoLettersOfFirstTwoWords,
  handleIcon,
  handleIconForPriority,
} from "../../../../Utils/helpers/helpers";
import { motion } from "framer-motion";
import { Tooltip } from "react-tooltip";
import DeleteModal from "../../../../Components/DeleteModal";
import Project from "../../TaskCreations/Project";

const TaskListing: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [isOpenProject, setIsOpenProject] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [editForProject, setEditForProject] = useState<boolean>(false);
  const [taskdata, setTaskDate] = useState();

  // Function to handle row click
  const handleRowClick = (index: number) => {
    setSelectedRow(selectedRow === index ? null : index);
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const [isOpenLogout, setIsOpenLogout] = useState<boolean>(false);
  const user = Cookies?.get("PMROLE");

  const {
    data: fullTaskData,
    isLoading: fullTaskLoading,
    refetch: fullMainTaskRefetch,
  } = useQuery<Task[]>({
    queryKey: ["MainByProjectId"],
    queryFn: () => getIndividualMainTaskByprojectId(id),
    placeholderData: keepPreviousData,
  });

  const { data: fullproject, refetch: fullProjectRefecth } =
    useQuery<ProjectInTask>({
      queryKey: ["projectfull"],
      queryFn: () => getProjectByprojectId(id),
      placeholderData: keepPreviousData,
    });

  const navigateBack = () => {
    user == "ADMIN"
      ? navigate("/admin/productlisting")
      : user == "TEAMLEAD"
      ? navigate("/lead/productlisting")
      : navigate("/employee/productlisting");
  };

  // Function to handle opening/closing the modal
  const toggleModal = () => {
    setIsOpenLogout(!isOpenLogout);
  };

  const toggleModalEditForTask = (task: any) => {
    setIsOpenLogout(!isOpenLogout);
    setEdit(true);
    setTaskDate(task);
  };
  const toggleModalEditForProject = () => {
    setIsOpenProject(true);
    setEditForProject(true);
  };
  if (fullTaskLoading) {
    return (
      <div>
        <CustomSpinner />
      </div>
    );
  }

  return (
    <Container fluid>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          className="header_background"
        >
          <Col
            xs={3}
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="white"
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <MdArrowBack
                size={25}
                onClick={() => navigateBack()}
                style={{ cursor: "pointer" }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{ fontWeight: "bolder", margin: 4 }}
                  className="name d-none d-md-flex mx-2"
                >
                  {" "}
                  {getFirstTwoLettersOfFirstTwoWords(fullproject?.name || "")}
                </p>
                <div>
                  <Tooltip id="my-tooltip" />
                  <p
                    style={{ fontWeight: "bolder", margin: 0 }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={fullproject?.description}
                    data-tooltip-place="top"
                  >
                    {fullproject?.name}
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={3}
            className="white d-flex justify-content-between align-items-center"
          >
            {" "}
            {Cookies?.get("PMROLE") !== "USER" && (
              <>
                <div onClick={toggleModalEditForProject}>
                  <MdEdit className="me-1" size={20} /> edit
                </div>
                <div onClick={() => setIsOpenDelete(true)}>
                  {" "}
                  <MdDeleteOutline className="me-1" size={20} />
                  delete
                </div>
                <div>
                  <MdOutlineStickyNote2 className="me-1" size={20} /> Close
                  Project
                </div>{" "}
              </>
            )}
          </Col>
          <Col
            xs={Cookies?.get("PMROLE") == "USER" ? 1 : 3}
            className="white "
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {" "}
            {handleIcon(fullproject?.status ?? "")}{" "}
            <p style={{ margin: 0, marginLeft: 2 }}>
              {fullproject?.status
                ?.toLowerCase()
                .replace(/(^|_)./g, (s: any) => s.slice(-1))}
            </p>
          </Col>

          {Cookies?.get("PMROLE") !== "USER" && (
            <Col xs={1}>
              <button
                className="sm w-100 buttonforproject buttonStyle"
                onClick={toggleModal}
                color="black"
              >
                New Task
              </button>
            </Col>
          )}
        </Row>
        <div style={{ marginTop: 10 }}>
          {fullTaskData?.length == 0 || !fullTaskData ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "60vh",
              }}
            >
              <motion.div
                whileHover={{ scale: 1.2 }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src="../../images/image.png"
                  style={{ height: "150px" }}
                  alt=""
                />
                <h2>No task found!</h2>
              </motion.div>
            </div>
          ) : (
            <Table responsive borderless size="sm">
              {fullTaskData?.map((Task: any, index: number) => (
                <tbody key={index + 1}>
                  <>
                    <tr key={index + 1}>
                      <td>
                        <h6 className="me-1"> {index + 1}.</h6>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <div
                              className="d-flex"
                              onClick={() => handleRowClick(index)}
                            >
                              {" "}
                              <div>
                                {" "}
                                <h6
                                  style={{
                                    fontWeight: "bolder",
                                    margin: 0,
                                    cursor: "pointer",
                                  }}
                                >
                                  {Task?.name}
                                </h6>
                                <Tooltip id="my-tooltip" />
                                <p
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={Task?.description}
                                  data-tooltip-place="top"
                                  style={{ cursor: "pointer" }}
                                >
                                  {Task?.description.substring(0, 130)}..{" "}
                                </p>
                              </div>
                            </div>

                            {/* <MdEventNote /> */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <p>
                          {handleIconForPriority(Task?.priority)}
                          {Task?.priority}
                        </p>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                          }}
                        >
                          {" "}
                          <div
                            className="d-none d-md-flex "
                            style={{ marginRight: 10 }}
                          >
                            {handleIcon(Task?.taskStatus)}
                            <p>
                              {Task?.taskStatus
                                .toLowerCase()
                                .replace(/(^|_)./g, (s: any) => s.slice(-1))}
                            </p>
                          </div>
                          <p style={{ margin: 0 }}>{Task?.team?.name}</p>
                        </div>
                      </td>

                      <td>
                        <p>
                          Created on :{" "}
                          {dayjs(Task?.startDate).format("YYYY-MM-DD")}
                        </p>
                      </td>

                      {Cookies?.get("PMROLE") !== "USER" && (
                        <td onClick={() => toggleModalEditForTask(Task)}>
                          <MdEdit size={20} />
                        </td>
                      )}
                    </tr>
                    {selectedRow === index && (
                      <tr>
                        <td colSpan={6}>
                          <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                          >
                            <DailyTaskListing
                              id={Task?.id}
                              fullproject={fullTaskData}
                            />
                          </motion.div>
                        </td>
                      </tr>
                    )}
                  </>
                </tbody>
              ))}
            </Table>
          )}
        </div>

        <ModalContainer
          isOpen={isOpenLogout}
          setIsOpen={setIsOpenLogout}
          setEdit={setEdit}
          title={
            edit ? (
              <p className="black">Edit task</p>
            ) : (
              <p className="black">Create task</p>
            )
          }
        >
          <SubTask
            refetch={fullMainTaskRefetch}
            setIsopen={setIsOpenLogout}
            edit={edit}
            data={taskdata}
          />
        </ModalContainer>

        <ModalContainer
          isOpen={isOpenDelete}
          setIsOpen={setIsOpenDelete}
          title={"Delete"}
        >
          <DeleteModal
            refetch={fullMainTaskRefetch}
            setIsopen={setIsOpenDelete}
          />
        </ModalContainer>

        <ModalContainer
          isOpen={isOpenProject}
          setIsOpen={setIsOpenProject}
          title={"Edit Project"}
        >
          <Project
            id={id}
            edit={editForProject}
            data={fullproject}
            refetch={fullProjectRefecth}
            setIsopen={setIsOpenLogout}
          />
        </ModalContainer>
      </motion.div>
    </Container>
  );
};

export default TaskListing;
