import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";
import { setValidationErrors } from "../../../../../Utils/helpers/helpers";

export const TaskSubmitHandler = (
  values: any,
  actions: any,
  refetch: Function,
  setIsopen: Function
) => {
  api
    .post("/api/tasks/create", values, true)
    .then(async function ([success, response]: any) {
      if (success) {
        toast.success("Task Created");
        actions.resetForm();
        refetch();
        setIsopen(false);
      } else {
        toast.error("Error in task creation");
      }
      actions.setSubmitting(false);
      if (response?.errors) {
        actions.setSubmitting(false);
        setValidationErrors(response?.errors, actions);
      }
    })
    .catch((err) => {
      actions.setSubmitting(false);
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          toastId: "Error in creating Task",
        });
      } else {
        toast?.error("Unexpected error occurred", {
          toastId: "error-occurred",
        });
      }
    });
};
