import dayjs from "dayjs";
import { api } from "./Api";

//Daily Attendance Api
export async function getAttendance(formData?: any, searchTerm?: any) {
  const queryParams = new URLSearchParams();
  if (formData.teamId) {
    queryParams.append("teamId", formData.teamId);
  }
  if (formData.assignedTo) {
    queryParams.append("assignedTo", formData.assignedTo);
  }
  if (searchTerm) {
    queryParams.append("searchTerm", searchTerm);
  }

  const [, data] = await api.get(`log/get/employee/try?${queryParams}`, true);
  return data;
}

//EMployee Status Api
export async function getEmployeeStatus(id: string) {
  const [, data] = await api.get(`/api/log/status/${id}`, true);
  return data;
}

//Git Status Api
export async function getGitStatus(id: string | null, currentDate: any) {
  const [, data] = await api.get(`/api/git/status/${id}/${currentDate}`, true);
  return data;
}

//Total Work Duration Api
export async function getWorkDuration(id: string | null, date: any) {
  const [, data] = await api.get(
    `/api/log/get-employee-work-duration/employeeId/${id}/date/${date}`,
    true
  );
  return data;
}

//All Teams Api
export async function getAllTeam() {
  const [, data] = await api.get("/api/teams", true);
  const allTeams = data?.teams?.map((data: { id: string; name: string }) => ({
    value: data.id,
    label: data.name,
  }));
  return allTeams;
}

//All Projects Api
export async function getAllProducts() {
  const [, data] = await api.get(`/api/product`, true);
  const project = data?.content?.map(
    (data: { id: string; name: string; projects: any }) => ({
      value: data.id,
      label: data.name,
      projects: data?.projects,
    })
  );
  return project || [];
}

//All Main Task
export async function getAllMainTask() {
  const [response, data] = await api.get(`/api/tasks/all`, true);
  const fullMainTaskData = data?.content || [];
  return fullMainTaskData;
}

// export async function getAllProjectByproductIdEmployeeId(
//   id: any,
//   searchDataForProject?: string
// ) {
//   const ADMIN = Cookies?.get("PMROLE") == "ADMIN";
//   const userId = Cookies?.get("PMUSERNAME");
//   const baseUrl = ADMIN
//     ? `/api/tasks/project/product/${id}?role=${"ADMIN"}`
//     : `/api/tasks/project/product/${id}/${userId}`;
//   const url = searchDataForProject
//     ? `${baseUrl}/${searchDataForProject}`
//     : baseUrl;
//   const [response, data] = await api.get(url, true);
//   const fullMainTaskData = data || [];
//   return fullMainTaskData;
// }

export async function getAllProjectByproductIdEmployeeId(id: any) {
  const [response, data] = await api.get(
    `/api/tasks/project/product/${id}`,
    true
  );
  const fullMainTaskData = data || [];
  return fullMainTaskData;
}

// export async function getIndividualMainTaskByprojectId(id: any, user?: string) {
//   const [response, data] = await api.get(
//     `/api/tasks/tasks/employee/${user}/${id}`,
//     true
//   );
//   const fullMainTaskData = data || [];
//   return fullMainTaskData;
// }

export async function getIndividualMainTaskByprojectId(id: any) {
  const [response, data] = await api.get(
    `/api/tasks/get-task-by-projectId/${id}`,
    true
  );
  const fullMainTaskData = data || [];
  return fullMainTaskData;
}

export async function getProjectByprojectId(id: any) {
  const [response, data] = await api.get(`/api/tasks/project/${id}`, true);
  const fullMainTaskData = data || [];
  return fullMainTaskData;
}

//All Daily Task
// export async function getAllDailyTaskForEmployee(
//   taskId?: any,
//   user?: string,
//   searchText?: string
// ) {
//   const [response, data] = await api.get(
//     `/api/tasks/daily-task/employee/${user}/${taskId}`
//   );
//   const fullMainTaskData = data || [];
//   return fullMainTaskData;
// }

export async function getAllDailyTaskForEmployee(
  taskId?: any,
  searchText?: string
) {
  const [response, data] = await api.get(`/api/tasks/by-task-id/${taskId}`);
  const fullMainTaskData = data || [];
  return fullMainTaskData;
}

//Task info
export async function getTaskById(taskId?: any, searchText?: string) {
  const [response, data] = await api.get(`/api/tasks/task_id/${taskId}`);
  const fullTaskData = data || [];
  return fullTaskData;
}
//All Employees Api
export async function getAllEmployess(teamId?: any) {
  const queryParams = new URLSearchParams();
  if (teamId) {
    queryParams.append("teamId", teamId);
  }
  const [, data] = await api.get(`/api/users/usernames?${queryParams}`, true);
  const employee = data?.map((data: { username: string; name: string }) => ({
    value: data?.username,
    label: data?.name,
  }));
  return employee;
}

//Employee Task List Api
export async function getEmployeeTaskList(id: number) {
  const [, data] = await api.get(`/api/tasks/filter?assignedTo=${id}`, true);
  return data;
}

//Employee Work Status
export async function getWorkStatus(workmode: string) {
  const date = dayjs().format("YYYY-MM-DD"); // Get current time
  const [, data] = await api.get(
    `/api/status/status/${date}/mode/${workmode}`,
    true
  );
  return data;
}

//Employee WorkCOunt Api for user
export async function getEmployeeWorkCount(id: string) {
  const [, data] = await api.get(
    `/api/tasks/daily-task/employee/${id}/status-count`,
    true
  );
  return data;
}

//Employee Attendance Api for admin
export async function getEmployeeAttendanceCount(date: string) {
  const [, data] = await api.get(
    `/api/status/cumulative-status-counts?date=${date}`,
    true
  );
  return data;
}

//Employee Attendance Api for admin
export async function getAllTimingFull(
  date: any,
  id?: any,
  searchData?: string
) {
  const url = "/api/log/logs/filter?";

  if (id) {
    const [, data] = await api.get(
      `${url}employeeId=${id}&date=${date}&search=${searchData}`,
      true
    );
    const Data = data || [];

    Data.sort((a: any, b: any) => b.timestamp - a.timestamp);
    Data.reverse();
    return Data;
  } else {
    const [, data] = await api.get(
      `${url}&date=${date}&search=${searchData}`,
      true
    );
    const Data = data || [];

    Data.sort((a: any, b: any) => b.timestamp - a.timestamp);
    Data.reverse();
    return Data;
  }
}

//Employee Work Count For Admin Api
export async function getAllEmployeeWorkCount() {
  const [, data] = await api.get(
    `/api/tasks/daily-task/cumulative-status-counts`,
    true
  );
  return data;
}

//Employee Status Api
export async function EmployeeStatus(id: any, date: any) {
  const [, data] = await api.get(`/api/status/status/${id}/${date}`, true);
  return data;
}

//All Daily Task
export async function getAllDailyTaskEmployee(id: any, date: any) {
  const [response, data] = await api.get(
    `/api/tasks/daily-task/employee/${id}/date/${date}`
  );
  const fullMainTaskData = data || [];
  return fullMainTaskData;
}

// All Daily Task
export async function getAllTimingForSingleEmployee(id: any) {
  const [response, data] = await api.get(`/api/log/get/employee/${id}`);
  const Data = data?.log || [];

  // Sort Data array in descending order based on timestamp
  Data.sort((a: any, b: any) => b.timestamp - a.timestamp);
  // Reverse the sorted array to bring the latest timestamp to the beginning
  Data.reverse();

  return Data;
}

export async function getAllTask(id?: any) {
  const [response, data] = await api.get(`/api/tasks/all`);
  const fullMainTaskData = data?.content || [];
  return fullMainTaskData;
}

export async function getAllTeamDataById(id: any) {
  const [response, data] = await api.get(
    `/api/tasks/daily-task/grouped-by-team/id/${id}`
  );
  const fullMainTeamData = data?.employees || [];
  return fullMainTeamData;
}
