import { Container, Col } from "reactstrap";
import { getEmployeeAttendanceCount } from "../../../../Api/WebsiteApi";
import { useQuery } from "@tanstack/react-query";
import { PresentCount } from "../../../../Type";
import {
  MdBusiness,
  MdHome,
  MdLocationOn,
  MdOutlineDoNotDisturb,
} from "react-icons/md";
import useTagStore from "../../../../Store/store";

const PresentCounts: React.FC = () => {
  const { currentDate } = useTagStore();

  const { data: EmployeeAttendanceCount }: any = useQuery<PresentCount>({
    queryKey: ["EmployeeAttendanceCount"],
    queryFn: () => getEmployeeAttendanceCount(currentDate),
  });

  return (
    <Container className="boxesSmall">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="forflexattendance">
          {" "}
          <MdBusiness size={20} className="ms-2" />{" "}
          <p>{EmployeeAttendanceCount?.PRESENT}</p>
        </div>{" "}
        <div className="forflexattendance">
          <MdHome />
          <p>{EmployeeAttendanceCount?.WFH}</p>
        </div>{" "}
        <div className="forflexattendance">
          {" "}
          <MdLocationOn />
          <p>{EmployeeAttendanceCount?.Site}</p>
        </div>{" "}
        <div className="forflexattendance">
          {" "}
          <MdOutlineDoNotDisturb />
          <p>{EmployeeAttendanceCount?.LEAVE}</p>
        </div>
      </div>
      <Col
        sm={12}
        className=" justify-content-center align-items-center mt-4"
        style={{
          borderRadius: "10px",
          textAlign: "center",
        }}
      ></Col>
    </Container>
  );
};

export default PresentCounts;
