import { useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Col, Table } from "reactstrap";
import {
  getAllDailyTaskForEmployee,
  getTaskById,
} from "../../../../Api/WebsiteApi";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import DailyTask from "../../TaskCreations/DailyTask";
import dayjs from "dayjs";
import { MdAdd, MdEdit } from "react-icons/md";
import Select from "react-select";
import { statusArray } from "../../../../Utils/Constants/SelectConstants";
import { DailyTaskUpdateSubmitHandler } from "./DailyTaskMethods";
import { DailyTaskType, Task } from "../../../../Type";
import { Tooltip } from "react-tooltip";
import {
  customStyles,
  handleIconForPriority,
} from "../../../../Utils/helpers/helpers";
import Cookies from "js-cookie";

const DailyTaskListing: React.FC<any> = (props: any) => {
  const id = props?.id;
  const user = Cookies?.get("PMUSERNAME");
  const [isOpenLogout2, setIsOpenLogout2] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [taskdata, setTaskDate] = useState();
  const toggleModal2 = () => {
    setIsOpenLogout2(!isOpenLogout2);
  };
  const toggleModalEditForTask = (task: any) => {
    setIsOpenLogout2(!isOpenLogout2);
    setEdit(true);
    setTaskDate(task);
  };
  const { data: fullDailyTaskData, refetch: fullDailyTaskRefetch } = useQuery<
    DailyTaskType[]
  >({
    queryKey: ["DailyTaskBytaskId", id, user],
    queryFn: () => getAllDailyTaskForEmployee(id, user),
    placeholderData: keepPreviousData,
    enabled: !!id,
  });

  const { data: TaskData } = useQuery<Task[]>({
    queryKey: ["TaskBytaskId", id],
    queryFn: () => getTaskById(id),
    placeholderData: keepPreviousData,
  });

  const Status = (value: any, id: any) => {
    DailyTaskUpdateSubmitHandler(value, id, fullDailyTaskRefetch);
  };

  const colorMap = {
    OPEN: "blue",
    COMPLETED: "green",
    IN_PROGRESS: "orange",
    FORGED: "red",
  };

  return (
    <>
      <Col sm={12} className="">
        <div>
          {" "}
          <button
            className="button buttonStyle2 buttonColor"
            style={{ marginLeft: 5 }}
            onClick={toggleModal2}
          >
            <MdAdd size={20} /> Daily
          </button>
        </div>

        {fullDailyTaskData && fullDailyTaskData?.length > 0 ? (
          <Table
            responsive
            borderless
            hover
            size="sm"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Assignee</th>
                <th>Time Taken</th>
                <th style={{ width: 150 }}>Status</th>
                <th>Priority</th>
                <th>Start Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            {fullDailyTaskData?.map(
              (DailyTask: DailyTaskType, index: number) => (
                <tbody key={DailyTask?.name + 1}>
                  <tr>
                    <th scope="row">{index + 1}</th>
                    <td>
                      <Tooltip id="my-tooltip" />
                      <div
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={DailyTask?.description}
                        data-tooltip-place="top"
                      >
                        {DailyTask?.name}
                      </div>
                    </td>

                    <td>{DailyTask?.assignedTo}</td>
                    <td>{DailyTask?.timeTaken}:Hrs</td>
                    <td>
                      {" "}
                      <Select
                        menuPosition="fixed"
                        placeholder="Select"
                        options={statusArray}
                        value={
                          statusArray?.find(
                            (itm: any) => itm?.value === DailyTask?.status
                          ) || null
                        }
                        onChange={(e) => {
                          Status(e?.value, DailyTask?.id);
                        }}
                        name="status"
                        isLoading={false}
                        loadingMessage={() => "Fetching Task Status"}
                        noOptionsMessage={() => "Task Status appears here"}
                        isSearchable={true}
                        required
                        className="black"
                        styles={customStyles(colorMap)}
                      />
                    </td>
                    <td>
                      {handleIconForPriority(DailyTask?.priority)}
                      {DailyTask?.priority}
                    </td>
                    <td> {dayjs(DailyTask?.date).format("MMM-DD")}</td>

                    <td onClick={() => toggleModalEditForTask(DailyTask)}>
                      <MdEdit size={15} />
                    </td>
                  </tr>
                </tbody>
              )
            )}
          </Table>
        ) : (
          <div style={{ marginLeft: 5 }}>
            <p style={{ color: "red" }}>No daily task found</p>
          </div>
        )}
      </Col>

      <ModalContainer
        isOpen={isOpenLogout2}
        setIsOpen={setIsOpenLogout2}
        title={
          edit ? (
            <h4 className="black">Edit daily task</h4>
          ) : (
            <h4 className="black">Create daily task</h4>
          )
        }
        setEdit={setEdit}
      >
        <DailyTask
          fullDailyTaskRefetch={fullDailyTaskRefetch}
          setIsOpen={setIsOpenLogout2}
          main={TaskData}
          data={taskdata}
          edit={edit}
        />
      </ModalContainer>
    </>
  );
};

export default DailyTaskListing;
