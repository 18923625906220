import React, { useState } from "react";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { Button, Col, Container, Row, Table } from "reactstrap";
import {
  getAllEmployess,
  getAllTeam,
  getAllTeamDataById,
} from "../../../../Api/WebsiteApi";
import Select from "react-select";
import CustomSpinner from "../../../../Components/Button/CustomSpinner";
import {
  ColorMap,
  statusArray,
} from "../../../../Utils/Constants/SelectConstants";
import Cookies from "js-cookie";
import ProjectsCounts from "../../Dashboard/Counts/ProjectsCount";
import ReactPaginate from "react-paginate";
import { MdFirstPage, MdLastPage } from "react-icons/md";
import { DailyTaskUpdateSubmitHandler } from "../DailyTaskListing/DailyTaskMethods";
import { AllteamData, AllteamsDetailData } from "../../../../Type";

const ConductMeeting: React.FC = () => {
  const [teamData, setTeamData] = useState<number | string>("");
  const [employeeData, setEmployeeData] = useState<number | string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const PAGE_SIZE = 2;

  const {
    data: allTeamsDetailData = [],
    isLoading: allTeamsDetailDataLoading,
    refetch: allTeamDetailDataRefetch,
  } = useQuery({
    queryKey: ["AllTeamsDatabyId", teamData],
    queryFn: () => getAllTeamDataById(teamData),
    placeholderData: [],
    enabled: Cookies.get("PMROLE") !== "USER" && !!teamData,
  });

  // Api for showing teams list
  const { data: allTeamsData = [] } = useQuery({
    queryKey: ["AllTeams"],
    queryFn: getAllTeam,
    placeholderData: [],
  });

  //Api for all Employees
  const { data: allEmployeeData }: any = useQuery({
    queryKey: ["AllEmployee"],
    queryFn: () => getAllEmployess(),
    placeholderData: keepPreviousData,
  });

  // Pagination
  const handlePageClick = (data: any) => {
    setCurrentPage(data?.selected);
  };
  const getPaginatedTasks = (tasks: any) => {
    const startIndex = currentPage * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return tasks.slice(startIndex, endIndex);
  };

  const Status = (value: any, id: any) => {
    DailyTaskUpdateSubmitHandler(value, id, allTeamDetailDataRefetch);
  };
  const customStyles = (colorMap: any) => ({
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? colorMap[state.data.value]
        : provided.backgroundColor,
      color: state.isSelected ? "black" : provided.color,
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,

      color: colorMap[state?.data?.value] || provided.color,
    }),
  });

  //If api is slow
  if (allTeamsDetailDataLoading) {
    return (
      <div>
        <CustomSpinner />
      </div>
    );
  }

  return (
    <section className="boxes p-4">
      {/* Counts of task */}
      <ProjectsCounts />
      <h5>Conduct Meeting</h5>
      <Container>
        <Row className="d-flex my-2 align-items-center">
          <div
            className="my-4 d-flex "
            style={{ justifyContent: "space-between", padding: 0 }}
          >
            <Col sm={3}>
              <Select
                value={allTeamsData?.find(
                  (option: any) => option?.value === teamData
                )}
                options={allTeamsData}
                onChange={(selectedOption) =>
                  setTeamData(selectedOption?.value)
                }
                isSearchable={true}
                placeholder="Teams"
                isLoading={false}
                loadingMessage={() => "Fetching teams"}
                noOptionsMessage={() => "Teams appears here"}
              />
            </Col>

            <Col sm={3}>
              <Select
                menuPosition="fixed"
                placeholder="Employee"
                options={allEmployeeData}
                value={
                  allEmployeeData?.find(
                    (itm: any) => itm?.value == employeeData
                  ) || ""
                }
                onChange={(e: any) => {
                  setEmployeeData(e?.value);
                }}
                isLoading={false}
                loadingMessage={() => "Fetching Employee"}
                noOptionsMessage={() => "Employee appears here"}
                isSearchable={true}
                isClearable
                required
                className="black"
              />
            </Col>

            <Col sm={1}>
              <Button
                color="danger"
                className="sm w-100"
                onClick={() => {
                  setSearchText("");
                  setTeamData("");
                }}
              >
                Reset
              </Button>
            </Col>
          </div>
        </Row>

        {allTeamsDetailData && allTeamsDetailData?.length > 0 ? (
          <div style={{ height: "55vh", overflowY: "auto" }}>
            <Table responsive size="sm" style={{ marginBottom: 50 }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Tasks</th>
                </tr>
              </thead>
              <tbody>
                {getPaginatedTasks(allTeamsDetailData || []).map(
                  (Task: AllteamsDetailData, index: number) => (
                    <tr key={index + 1}>
                      <td>{Task.name}</td>

                      <td>
                        <Table>
                          <thead>
                            <tr>
                              <th>Daily Task</th>
                              <th>Status</th>
                              <th>Priority</th>
                              <th>Time taken</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Task?.tasks?.map(
                              (task: AllteamData, taskIndex: number) => (
                                <>
                                  <tr key={taskIndex + 1}>
                                    <td>{task?.task_name}</td>
                                    <td>
                                      <td>
                                        {" "}
                                        <Select
                                          placeholder="Select"
                                          options={statusArray}
                                          value={
                                            statusArray?.find(
                                              (itm: any) =>
                                                itm?.value ===
                                                task?.status.toUpperCase()
                                            ) || null
                                          }
                                          onChange={(e) => {
                                            Status(e?.value, task?.task_id);
                                          }}
                                          name="status"
                                          isLoading={false}
                                          loadingMessage={() =>
                                            "Fetching Task Status"
                                          }
                                          noOptionsMessage={() =>
                                            "Task Status appears here"
                                          }
                                          isSearchable={true}
                                          required
                                          className="black"
                                          styles={customStyles(ColorMap)}
                                        />
                                      </td>
                                    </td>
                                    <td>{task?.priority}</td>
                                    <td>{task?.timeTaken}:Hrs</td>
                                  </tr>
                                </>
                              )
                            )}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
            {/* Pagination */}
            <ReactPaginate
              previousLabel={<MdFirstPage />}
              nextLabel={<MdLastPage />}
              breakLabel={"..."}
              pageCount={Math?.ceil(
                (allTeamsDetailData?.length || 0) / PAGE_SIZE
              )}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "47vh",
              overflowY: "hidden",
              margin: 0,
              padding: 0,
            }}
          >
            <img
              style={{ borderRadius: 5, height: "450px" }}
              className="img-fluid"
              src="../images/9.gif"
              alt=""
            />
          </div>
        )}
      </Container>
    </section>
  );
};

export default ConductMeeting;
