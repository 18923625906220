import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";
import { setValidationErrors } from "../../../../../Utils/helpers/helpers";

export const ProjectSubmitHandler = async (
  values: any,
  actions: any,
  refetch: Function,
  setIsopen: Function
): Promise<any> => {
  try {
    const [success, response] = await api.post(
      "/api/project/create",
      values,
      true
    );
    if (success) {
      toast.success("Project Created Successfully");
      actions.resetForm();
      refetch();
      setIsopen(false);
    } else {
      toast.error("Error in project creation");
    }
    actions.setSubmitting(false);
    if (response?.errors) {
      actions.setSubmitting(false);
      setValidationErrors(response?.errors, actions);
    }
  } catch (err) {
    actions.setSubmitting(false);
    if (err) {
      toast.error("Error when creating project", {
        toastId: "error-occured",
      });
    } else {
      toast?.error("Unexpected error occurred", {
        toastId: "error-occurred",
      });
    }
  }
};
