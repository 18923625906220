import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";

//Api for checkin
export const CheckInHandler = async (
  setIsCheckedIn: Function,
  setIsCheckedOut: Function,
  userId: string,
  currentTime: string
) => {
  try {
    const payload = {
      username: userId,
      checkInInstance: currentTime,
    };
    const [success, response] = await api.post("/api/log/operation", payload);
    if (success) {
      toast.success("Check In Successful");
      setIsCheckedIn(true);
      setIsCheckedOut(false);
      return response?.checkInInstance;
    } else {
      toast.error("Check In Not Successful");
    }
  } catch (err) {
    toast.error("Check In Not Successfull");
  }
};

//Api for checkout
export const CheckOutHandler = async (
  setIsCheckedOut: Function,
  setIsCheckedIn: Function,
  currentTime: string,
  userId: string
) => {
  try {
    const payload = {
      username: userId,
      checkOutInstance: currentTime,
    };
    const [success, response] = await api.post("/api/log/operation", payload);

    if (success) {
      toast.success("Check Out Successfull");
      setIsCheckedOut(true);
      setIsCheckedIn(false);
      return response?.checkOutInstance;
    } else {
      toast.error("Check Out Not Successfull");
    }
  } catch (err) {
    toast.error("Check Out Not Successfull");
  }
};

//Api for git Push
export const gitPush = async (
  currentTime: any,
  updatedValue: any,
  gitStatusRefetch: Function
) => {
  try {
    const data = {
      date: currentTime,
      employeeId: updatedValue,
    };
    const [success, response] = await api.post("/api/git/create", data);
    if (success) {
      toast.success("Marked successfully");
      gitStatusRefetch();
    } else {
      toast.error("Action was unsuccessful");
    }
  } catch (err) {
    toast.error("Action was unsuccessful");
  }
};

//Api for git Push
export const workStatus = async (
  status: any,
  userid: any,
  date: any,
  AllEmployeeStatusRefetch: Function
) => {
  if (status) {
    const values = { status: status, date: date, employeeId: userid };

    try {
      const [success, response] = await api.post(
        "/api/status/create",
        values,
        true
      );
      if (success) {
        toast.success("Marked successfully");
        AllEmployeeStatusRefetch();
      } else {
        toast.error("Action was unsuccessful");
      }
    } catch (err) {
      toast.error("Action was unsuccessful");
    }
  }
};
