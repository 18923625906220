import { toast } from "react-toastify";
import { api } from "../../../../../Api/Api";

interface TaskUpdateValues {
  taskStatus: string;
}

export const DailyTaskUpdateSubmitHandler = (
  values: TaskUpdateValues,
  id: string | Number,
  fullDailyTaskRefetch: any
) => {
  const value = { taskStatus: values };

  api
    .put(`/api/tasks/daily-task/${id}/status`, value, true)
    .then(async function ([success, response]: any) {
      if (success) {
        toast.success("Daily task updated ");
        fullDailyTaskRefetch();
      } else {
        toast.error("Error in task updated");
      }
    })
    .catch((err: any) => {
      console.log(err);
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message, {
          toastId: "Error in updated daily task",
        });
      } else {
        toast?.error("Unexpected error occurred", {
          toastId: "error-occurred",
        });
      }
    });
};
