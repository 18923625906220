import * as Yup from "yup";
import { PasswordSchema } from "../../../Utils/Formik/FormikCommonValidationSchemas";

//Validation
export const PasswordValidation = Yup.object().shape({
  currentPassword: PasswordSchema.required("Password is required"),
  newPassword: PasswordSchema.required("Password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});
// initial values
export const initializePasswordInitialValues = () => {
  return {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
};
