import React, { useState } from "react";
import { Col, FormGroup, Input, Row, Table } from "reactstrap";
import ModalContainer from "../../../../Components/Modal/ModalContainer";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import Project from "../../TaskCreations/Project";
import { ProjectList } from "../../../../Type";
import {
  getFirstTwoLettersOfFirstTwoWords,
  handleIcon,
} from "../../../../Utils/helpers/helpers";
import { MdAdd } from "react-icons/md";
import { motion } from "framer-motion";

const ProjectListing: React.FC<any> = ({ data, id, refetch }) => {
  const navigate = useNavigate();
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const status = (value: string) => {
    Cookies?.get("PMROLE") === "ADMIN"
      ? navigate(`/admin/tasklisting/${value}`)
      : Cookies?.get("PMROLE") === "TEAMLEAD"
      ? navigate(`/lead/tasklisting/${value}`)
      : navigate(`/employee/tasklisting/${value}`);
  };
  const SearchForm = (searchValue: string) => {
    // setSearchDataForProject(searchValue);
  };
  const toggleModal = () => {
    setIsOpenLogout(!isOpenLogout);
  };

  if (data?.length == 0 || !data) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "60vh",
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          whileHover={{ scale: 1.2 }}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src="../images/image.png" style={{ height: "150px" }} alt="" />
          <h2>No projects found!</h2>
        </motion.div>
        {Cookies?.get("PMROLE") === "ADMIN" ? (
          <Col sm={1}>
            <button
              className="w-100 button buttonColor buttonStyle my-2"
              onClick={toggleModal}
            >
              <MdAdd size={20} className="me-1" /> Project
            </button>
          </Col>
        ) : null}
        <ModalContainer
          isOpen={isOpenLogout}
          setIsOpen={setIsOpenLogout}
          title={"Create Project"}
        >
          <Project id={id} refetch={refetch} setIsopen={setIsOpenLogout} />
        </ModalContainer>
      </div>
    );
  }
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Col sm={Cookies?.get("PMROLE") == "ADMIN" ? 10 : 12}>
            <FormGroup>
              <Input
                id="exampleSearch"
                name="search"
                placeholder="search for projects"
                onChange={(e) => {
                  SearchForm(e.target.value);
                }}
                type="search"
              />
            </FormGroup>
          </Col>
          {Cookies?.get("PMROLE") === "ADMIN" ? (
            <Col sm={1}>
              <button
                className="w-80 button buttonColor buttonStyle"
                onClick={() => toggleModal()}
              >
                <MdAdd size={18} className="me-1" /> Project
              </button>
            </Col>
          ) : null}
        </div>
        <div>
          <Table responsive size="sm" className="formouse">
            <tbody>
              {data?.map((Task: ProjectList, index: number) => (
                <tr key={index + 1} onClick={() => status(Task?.id)}>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <h6
                        className="name d-none d-md-block"
                        style={{
                          marginRight: 10,
                          fontWeight: "bolder",
                        }}
                      >
                        {getFirstTwoLettersOfFirstTwoWords(Task?.name)}
                      </h6>
                      <div style={{ display: "flex" }}>
                        <div>
                          {" "}
                          <h6 style={{ fontWeight: "bold", margin: 0 }}>
                            {Task?.name}
                          </h6>
                          <p>{Task?.description.substring(0, 100)}..</p>
                        </div>

                        {/* <MdEventNote /> */}
                      </div>
                    </div>
                  </td>

                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      {" "}
                      <div
                        className="d-none d-md-flex circle white mx-1"
                        style={{
                          backgroundColor:
                            Task?.team?.name == "Application Developement"
                              ? "#9747FF"
                              : Task?.team?.name == "ML Developement"
                              ? "#2F2347"
                              : Task?.team?.name == "Backend Developement"
                              ? "#26CF56"
                              : Task?.team?.name == "Frontend Developement"
                              ? "#0039CB"
                              : Task?.team?.name == "HW Developement"
                              ? "#00354C"
                              : Task?.team?.name == "Embedded Developement"
                              ? "#940000"
                              : "",
                        }}
                      >
                        {getFirstTwoLettersOfFirstTwoWords(Task?.team?.name)}
                      </div>
                      <p style={{ margin: 0 }}>{Task?.team?.name}</p>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                        }}
                      >
                        <div>{handleIcon(Task?.status)}</div>
                        <div>
                          {Task?.status
                            .toLowerCase()
                            .replace(/(^|_)./g, (s: any) => s.slice(-1))}
                        </div>
                      </div>

                      <div>
                        <p>Created On: {Task?.startDate}</p>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Row>
      <ModalContainer
        isOpen={isOpenLogout}
        setIsOpen={setIsOpenLogout}
        title={"Create Project"}
      >
        <Project id={id} refetch={refetch} setIsopen={setIsOpenLogout} />
      </ModalContainer>
    </motion.div>
  );
};

export default ProjectListing;
