import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { Logout } from "../../Utils/helpers/helpers";

const AlertModal = (props: any) => {
  const navigate = useNavigate();

  return (
    <div className="form-wrap text-center">
      <Row className="g-3">
        <Col lg={12}>
          <div className="modal-warning-text-wrap">
            <small className="sub-text">Do you really want to Logout.</small>
          </div>
        </Col>

        <Col lg={12}>
          <div className="submit-btn-wrap centered">
            <Button
              color={"success"}
              outline
              className="sm m-2"
              onClick={() => props?.setIsOpenLogout(false)}
            >
              Cancel
            </Button>
            <Button
              color={"danger"}
              className="sm text-white m-2"
              onClick={() => {
                props?.setIsOpenLogout(false);
                Logout(navigate);
              }}
            >
              Logout
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AlertModal;
