import CustomButton from "../../../Components/Button/CustomButton";
import TextError from "../../../Components/Errors/TextErrors";
import { Formik, ErrorMessage } from "formik";
import { Form, Row, Col, Input } from "reactstrap";
import { PasswordChangeHandler } from "./PassWordChange";
import {
  initializePasswordInitialValues,
  PasswordValidation,
} from "./FormikMethods";

const ChangePassword = (props: any) => {
  return (
    <div className="form-wrap text-center">
      <Row className="g-3">
        <Col lg={12}>
          <Formik
            validationSchema={PasswordValidation}
            initialValues={initializePasswordInitialValues}
            enableReinitialize
            onSubmit={(values: any, actions: any) => {
              PasswordChangeHandler(values, actions, props?.setIsOpenLogout);
            }}
          >
            {({
              values,
              isSubmitting,
              handleChange,
              setFieldValue,
              handleSubmit,
            }: any) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="g-4">
                    {/* Task name input */}
                    <Col md={12}>
                      <Input
                        type="password"
                        name="currentPassword"
                        placeholder="Current password"
                        value={values?.currentPassword}
                        aria-describedby="newpass"
                        onChange={handleChange}
                        style={{ border: 0 }}
                        required
                      />
                      <ErrorMessage
                        name="currentPassword"
                        component={TextError}
                      />
                    </Col>
                    <Col md={12}>
                      <Input
                        type="password"
                        name="newPassword"
                        placeholder="New password"
                        value={values?.newPassword}
                        aria-describedby="newpass"
                        onChange={handleChange}
                        style={{ border: 0 }}
                        required
                      />
                      <ErrorMessage name="newPassword" component={TextError} />
                    </Col>
                    <Col md={12}>
                      <Input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm password"
                        value={values?.confirmPassword}
                        aria-describedby="newpass"
                        onChange={handleChange}
                        style={{ border: 0 }}
                        required
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component={TextError}
                      />
                    </Col>

                    <Col md={3}>
                      <CustomButton
                        isLoading={isSubmitting}
                        label="Submit"
                        color="primary"
                        className="sm submit-btn w-100"
                      />
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default ChangePassword;
