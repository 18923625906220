import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import RoutesHandler from "./Pages/Routes";
import ErrorBoundary from "./Utils/ErrorBoundary/error";

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <RoutesHandler />
    </ErrorBoundary>
  );
};

export default App;
