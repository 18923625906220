import Cookies from "js-cookie";
import { clearData } from "../../Lib/keystorage";
import { USER_ROLES } from "../Constants/AuthenticationConstants";
import {
  MdOutlinePending,
  MdOutlinePlayCircle,
  MdOutlineOfflinePin,
  MdCancel,
  MdFlag,
  MdOutlineFilePresent,
  MdBugReport,
  MdBusiness,
  MdHome,
  MdLocationOn,
  MdOutlineDoNotDisturb,
} from "react-icons/md";
import { components } from "react-select";
import { TiSpanner } from "react-icons/ti";

const Logout = async (navigate: Function) => {
  clearData();
  navigate("/");
};
const AuthChecker = (navigate: Function, path: string) => {
  if (Cookies.get("PMAUTH_TOKEN") !== null) {
    if (Cookies.get("PMROLE") === USER_ROLES?.ADMIN) {
      navigate("/admin/dashboard", { replace: true });
    } else if (Cookies.get("PMROLE") === "USER") {
      navigate("/employee/dashboard", { replace: true });
    } else if (Cookies.get("PMROLE") === "TEAMLEAD") {
      navigate("/lead/dashboard", { replace: true });
    }
  } else {
    navigate(path, { replace: true });
  }
};
const setValidationErrors = (errors: any, actions: any) => {
  Object?.keys(errors)?.map((fieldName) =>
    errors?.[fieldName]?.map((errorMessage: string, index: number) =>
      actions?.setFieldError(fieldName, errorMessage)
    )
  );
};

const getFirstTwoLettersOfFirstTwoWords = (str: string) => {
  const words = str.trim().split(/\s+/);
  const firstTwoWords = words.slice(0, 2);
  return firstTwoWords
    .map((word: string) => word.slice(0, 1).toUpperCase())
    .join(" ");
};

const handleIcon = (value: any) => {
  if (value === "IN_PROGRESS") {
    return <MdOutlinePending size={20} color="orange" className="me-1" />;
  }
  if (value === "OPEN") {
    return <MdOutlinePlayCircle size={20} color="blue" className="me-1" />;
  }
  if (value === "COMPLETED") {
    return <MdOutlineOfflinePin size={20} color="green" className="me-1" />;
  }
  if (value === "FORGED" || "CLOSED") {
    return <MdCancel size={20} color="red" className="me-1" />;
  }
};

const getPriorityColor = (priority: string): string | null => {
  switch (priority.toUpperCase()) {
    case "LOW":
      return "blue";
    case "MEDIUM":
      return "orange";
    case "HIGH":
      return "red";
    default:
      return null;
  }
};
const handleIconForPriority = (value: string | number) => {
  const color = getPriorityColor(value?.toString());
  if (color) {
    return <MdFlag color={color} className="me-1" size={20} />;
  }

  return null;
};
const CustomSingleValue = (props: any) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <span style={{ marginRight: 8 }}>{handleIcon(data?.value)}</span>
      {props.data.label}
    </components.SingleValue>
  );
};

const CustomSingleValuePriority = (props: any) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <span style={{ marginRight: 8 }}>
        {handleIconForPriority(data?.value)}
      </span>
      {props.data.label}
    </components.SingleValue>
  );
};

const CustomSingleValueType = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <span>
        {props.data.value == "TESTING" ? (
          <MdOutlineFilePresent color="blue" />
        ) : props.data.value == "DEV" ? (
          <TiSpanner color="green" />
        ) : props.data.value == "BUG_FIX" ? (
          <MdBugReport color="red" />
        ) : (
          ""
        )}
      </span>{" "}
      {props.data.label}
    </components.SingleValue>
  );
};
const customStyles = (colorMap: any) => ({
  option: (provided: any, state: any) => ({
    ...provided,
    color: colorMap[state.data.value] || provided.color,
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: colorMap[state.data.value] || provided.color,
  }),
});

const customStylesPriority = (colorMapPriority: any) => ({
  option: (provided: any, state: any) => ({
    ...provided,
    color: colorMapPriority[state.data.value] || provided.color,
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: colorMapPriority[state.data.value] || provided.color,
  }),
});

const customStylesType = (colorMapType: any) => ({
  option: (provided: any, state: any) => ({
    ...provided,
    color: colorMapType[state.data.value] || provided.color,
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: colorMapType[state.data.value] || provided.color,
  }),
});

const CustomOptionStatus = (props: any) => {
  return (
    <components.Option {...props}>
      <span>
        {props.data.value == "OPEN" ? (
          <MdOutlinePlayCircle color="blue" />
        ) : props.data.value == "IN_PROGRESS" ? (
          <MdOutlinePending color="orange" />
        ) : props.data.value == "COMPLETED" ? (
          <MdOutlineOfflinePin color="green" />
        ) : props.data.value == "FORGED" ? (
          <MdCancel color="red" />
        ) : (
          ""
        )}
      </span>{" "}
      {props.children}
    </components.Option>
  );
};

const CustomOptionPriority = (props: any) => {
  return (
    <components.Option {...props}>
      <span>
        {props.data.value == "LOW" ? (
          <MdFlag color="blue" />
        ) : props.data.value == "MEDIUM" ? (
          <MdFlag color="orange" />
        ) : (
          <MdFlag color="red" />
        )}
      </span>{" "}
      {props.children}
    </components.Option>
  );
};

const CustomOptionType = (props: any) => {
  return (
    <components.Option {...props}>
      <span>
        {props.data.value === "TESTING" ? (
          <MdOutlineFilePresent color="blue" />
        ) : props.data.value === "DEV" ? (
          <TiSpanner color="green" />
        ) : props.data.value === "BUG_FIX" ? (
          <MdBugReport color="red" />
        ) : (
          ""
        )}
      </span>{" "}
      {props.children}
    </components.Option>
  );
};

// Icons for attendance
const handleIconForAttendance = (value: any) => {
  if (value === "OFFICE") {
    return <MdBusiness />;
  }
  if (value === "WFH") {
    return <MdHome />;
  }
  if (value === "SITE") {
    return <MdLocationOn />;
  }
  if (value === "LEAVE") {
    return <MdOutlineDoNotDisturb />;
  }
};

export {
  AuthChecker,
  Logout,
  setValidationErrors,
  getFirstTwoLettersOfFirstTwoWords,
  handleIcon,
  handleIconForPriority,
  customStyles,
  CustomOptionStatus,
  CustomOptionPriority,
  CustomOptionType,
  CustomSingleValue,
  CustomSingleValuePriority,
  CustomSingleValueType,
  customStylesPriority,
  customStylesType,
  handleIconForAttendance,
};
