import dayjs from "dayjs";
import { useState } from "react";
import { Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import { getAllTimingFull } from "../../../../Api/WebsiteApi";
import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { AiOutlineStop } from "react-icons/ai";
import { MdAvTimer, MdBusiness, MdHome, MdLocationOn } from "react-icons/md";
import useTagStore from "../../../../Store/store";

const AllattendanceForAdmin = () => {
  const { currentDate } = useTagStore();
  const [formData, setFormData] = useState<string>("");
  const role = Cookies.get("PMROLE");
  const { data: fullTimingData, isLoading: fullTimingLoading } = useQuery({
    queryKey: ["FullTiming", currentDate, formData],
    queryFn: () => getAllTimingFull(currentDate, formData),
    enabled: role !== "USER",
  });

  const handleIcon = (value: string) => {
    if (value === "OFFICE") {
      return <MdBusiness />;
    }
    if (value === "WFH") {
      return <MdHome />;
    }
    if (value === "SITE") {
      return <MdLocationOn />;
    }
    if (value === "LEAVE") {
      return <AiOutlineStop />;
    }
  };

  return (
    <Container className="boxes">
      <Row className="py-4">
        <ListGroup flush style={{ overflowY: "auto", maxHeight: "50vh" }}>
          {fullTimingData?.map((data: any, index: any) => (
            <ListGroupItem style={{ padding: 10 }} key={index + 1}>
              <div className="forAttendanceFlex">
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div className="circle me-1">
                    {data?.employeeId?.substring(0, 2)}
                  </div>
                  <div>
                    <p style={{ margin: 0 }}>
                      {data?.employeeName ? "" : "ARUN KUMAR"}
                    </p>
                    <p>{data?.employeeId}</p>
                  </div>
                </div>
                <div>
                  <div className="forAttendanceFlex">
                    <div>{handleIcon(data?.taskStatus)}</div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#0E4FA4",
                        color: "white",
                        padding: 4,
                        borderRadius: 10,
                        justifyContent: "space-between",
                      }}
                    >
                      <MdAvTimer size={25} />
                      <p style={{ margin: 0 }}>
                        {dayjs(data?.checkInInstance).format("hh:mm")}
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p>
                      {dayjs(data?.checkInInstance).format("hh:mm A")} -{" "}
                      {data?.checkOutInstance
                        ? dayjs(data?.checkOutInstance).format("hh:mm A")
                        : "Not checked out"}{" "}
                    </p>
                  </div>
                </div>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Row>
    </Container>
  );
};

export default AllattendanceForAdmin;
