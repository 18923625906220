import { useState } from "react";
import {
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import CheckInOut from "../../Dashboard/CheckIn&Out";
import {
  MdBusiness,
  MdHome,
  MdLocationOn,
  MdSearch,
  MdArrowForward,
  MdInfoOutline,
  MdOutlineDoNotDisturb,
  MdAvTimer,
} from "react-icons/md";

// API functions
import {
  getAllTimingForSingleEmployee,
  getAllTimingFull,
  getEmployeeAttendanceCount,
} from "../../../../Api/WebsiteApi";
import { useNavigate } from "react-router";
import { AlltimingType, EmployeeAttendanceCount } from "../../../../Type";
import { handleIconForAttendance } from "../../../../Utils/helpers/helpers";
import useTagStore from "../../../../Store/store";

const Attendance = () => {
  const role = Cookies.get("PMROLE");
  const userId = Cookies.get("PMUSERNAME");
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState<string>("");
  const [activeStatus, setActiveStatus] = useState<string>("");
  const { currentDate } = useTagStore();

  //Status of employee(wfh or office etc)
  const status = (value: string) => {
    setActiveStatus(value);
  };

  //navigation for visiting more attendance details
  const allAttendance = () => {
    navigate("/admin/allattendance");
  };

  //search in attendance
  const SearchFormAttendance = (searchValue: string) => {
    setSearchData(searchValue);
  };

  //Attendance of single employee
  const { data: allTimingData, refetch: alltimingRefetch } = useQuery<
    AlltimingType[]
  >({
    queryKey: ["AllTiming", userId],
    queryFn: () => getAllTimingForSingleEmployee(userId),
    enabled: role !== "ADMIN",
  });

  //All employee attendance data
  const { data: fullTimingData } = useQuery<AlltimingType[]>({
    queryKey: ["FullTiming", currentDate, searchData, activeStatus],
    queryFn: () => getAllTimingFull(currentDate, activeStatus, searchData),
    enabled: role !== "USER",
  });

  //employee counts data
  const { data: EmployeeAttendanceCount } = useQuery<EmployeeAttendanceCount>({
    queryKey: ["EmployeeAttendanceCount"],
    queryFn: () => getEmployeeAttendanceCount(currentDate),
    enabled: role !== "ADMIN",
  });

  return (
    <Container className="boxes">
      <Row className="py-4">
        {/* Component for checkin and checkout */}
        {Cookies.get("PMROLE") !== "ADMIN" && (
          <CheckInOut refetch={alltimingRefetch} />
        )}

        {/* Attendance Part */}
        <Col sm={12}>
          <div className="forAttendanceFlex">
            <h5 className="tbold">
              {Cookies?.get("PMROLE") == "ADMIN"
                ? "Daily Attendance"
                : "All Attendance"}
              <MdInfoOutline size={20} className="ms-1" />
            </h5>

            {Cookies?.get("PMROLE") == "ADMIN" ? (
              <span
                style={{ fontWeight: "bolder", marginRight: 2, padding: 10 }}
                onClick={() => status("")}
                className={`forflexattendance ${
                  activeStatus === "" ? "active" : ""
                }`}
              >
                All
              </span>
            ) : (
              ""
            )}

            <h6 style={{ fontWeight: "lighter" }} onClick={allAttendance}>
              {" "}
              See More{" "}
              <MdArrowForward
                className="formouse ms-1"
                size={15}
                color="#0756ab"
              />
            </h6>
          </div>
          {Cookies.get("PMROLE") === "ADMIN" && (
            <Container className="boxesSmall">
              <Row className="my-4">
                <Col
                  xs={3}
                  className={`forflexattendance ${
                    activeStatus === "PRESENT" ? "active" : ""
                  } `}
                  onClick={() => status("PRESENT")}
                >
                  <div className="iconsinattendacedaily">
                    <MdBusiness size={25} className="me-1" />{" "}
                    <p style={{ margin: 0 }}>
                      {EmployeeAttendanceCount?.PRESENT}
                    </p>
                  </div>
                  <div>Office</div>
                </Col>
                <Col
                  xs={3}
                  className={`forflexattendance ${
                    activeStatus === "WFH" ? "active" : ""
                  }`}
                  onClick={() => status("WFH")}
                >
                  <div className="iconsinattendacedaily">
                    <MdHome size={25} className="me-1" />
                    <p style={{ margin: 0 }}>{EmployeeAttendanceCount?.WFH}</p>
                  </div>
                  <div>Home</div>
                </Col>{" "}
                <Col
                  xs={3}
                  className={`forflexattendance ${
                    activeStatus === "SITE" ? "active" : ""
                  }`}
                  onClick={() => status("SITE")}
                >
                  {" "}
                  <div className="iconsinattendacedaily">
                    {" "}
                    <MdLocationOn size={25} className="me-1" />
                    <p style={{ margin: 0 }}>{EmployeeAttendanceCount?.WFH}</p>
                  </div>
                  <div>Site</div>
                </Col>{" "}
                <Col
                  xs={3}
                  className={`forflexattendance ${
                    activeStatus === "LEAVE" ? "active" : ""
                  }`}
                  onClick={() => status("LEAVE")}
                >
                  {" "}
                  <div className="iconsinattendacedaily">
                    <MdOutlineDoNotDisturb size={25} className="me-1" />
                    <p style={{ margin: 0 }}>
                      {EmployeeAttendanceCount?.LEAVE}
                    </p>
                  </div>
                  <div>Absent</div>
                </Col>
              </Row>
              <Col
                sm={12}
                className=" justify-content-center align-items-center mt-4"
                style={{
                  borderRadius: "10px",
                  textAlign: "center",
                }}
              ></Col>
            </Container>
          )}
          <FormGroup>
            <InputGroup style={{ borderRadius: 25 }} className="my-4">
              <Input
                id="search"
                name="search"
                placeholder="Search"
                type="search"
                onChange={(e) => SearchFormAttendance(e.target.value)}
                style={{
                  borderRadius: "25px 0 0 25px",
                  borderRight: "none",
                  backgroundColor: "#F5F3FF",
                }}
              />
              <InputGroupText
                style={{
                  borderRadius: "0 25px 25px 0",
                  borderLeft: "none",
                  backgroundColor: "#F5F3FF",
                }}
              >
                <MdSearch size={25} color="#0756ab" />
              </InputGroupText>
            </InputGroup>
          </FormGroup>
          {/* For admin */}
          {Cookies.get("PMROLE") !== "USER" ? (
            <ListGroup flush style={{ overflowY: "auto", maxHeight: "50vh" }}>
              {fullTimingData?.map((data: AlltimingType, index: number) => (
                <ListGroupItem style={{ padding: 0 }} key={index + 1}>
                  <div className="forAttendanceFlex">
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div className="circle me-1 ">
                        {data?.id?.substring(0, 2)}
                      </div>
                      <div>
                        <p style={{ margin: 0 }}>
                          {data?.username ? "" : "ARUN KUMAR"}
                        </p>
                        <p>{data?.id}</p>
                      </div>
                    </div>
                    <div>
                      <div className="forAttendanceFlex">
                        <div>{handleIconForAttendance(data?.taskStatus)}</div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#0E4FA4",
                            color: "white",
                            padding: 4,
                            borderRadius: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <MdAvTimer size={25} />
                          <p style={{ margin: 0 }}>
                            {dayjs(data?.checkInInstance).format("hh:mm")}
                          </p>
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p>
                          {dayjs(data?.checkInInstance).format("hh:mm A")} -{" "}
                          {data?.checkOutInstance
                            ? dayjs(data?.checkOutInstance).format("hh:mm A")
                            : "Not checked out"}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <ListGroup flush style={{ overflowY: "auto", maxHeight: "50vh" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Date</p>
                <p>Timing</p>
                <p>Duration</p>
              </div>
              {allTimingData?.map((data: AlltimingType, index: number) => (
                <ListGroupItem style={{ padding: 0 }} key={index + 1}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p>{dayjs(data.checkInInstance).format("YYYY-MM-DD")}</p>

                    <p style={{ textAlign: "left", margin: 0 }}>
                      {" "}
                      {dayjs(data?.checkInInstance).format("hh:mm A")} -{" "}
                      {data?.checkOutInstance
                        ? dayjs(data?.checkOutInstance).format("hh:mm A")
                        : "Not checked out"}
                    </p>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>{handleIconForAttendance(data?.taskStatus)}</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#0E4FA4",
                          color: "white",
                          padding: 4,
                          borderRadius: 10,
                          justifyContent: "space-between",
                        }}
                      >
                        <MdAvTimer size={20} />
                        <p style={{ margin: 0 }}>
                          {dayjs(data?.checkInInstance).format("hh:mm")}-hrs
                        </p>
                      </div>
                    </div>
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Attendance;
