import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface ModalPropType {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  children?: React.ReactNode;
  setEdit?: (edit: boolean) => void;
  title: any;
}

const ModalContainer: React.FC<ModalPropType> = ({
  isOpen,
  setIsOpen,
  children,
  setEdit,
  title,
}) => {
  const handleClose = () => {
    if (setEdit) {
      setEdit(false);
    }
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      scrollable={true}
      centered={true}
      toggle={() => handleClose}
      title=""
    >
      {" "}
      <ModalHeader toggle={handleClose}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default ModalContainer;
