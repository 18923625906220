import { toast } from "react-toastify";
import { api } from "../../../Api/Api";
import { setValidationErrors } from "../../../Utils/helpers/helpers";

export const PasswordChangeHandler = async (
  values: any,
  actions: any,
  setIsOpenLogout: Function
): Promise<any> => {
  try {
    const [success, response] = await api.patch(
      "/api/auth/change-password",
      values,
      true
    );
    if (success) {
      toast.success("Password Changed Successfully");
      actions.resetForm();
      setIsOpenLogout(false);
    } else {
      toast.error("Error in changing password ");
    }
    actions.setSubmitting(false);
    if (response?.errors) {
      actions.setSubmitting(false);
      setValidationErrors(response?.errors, actions);
    }
  } catch (err) {
    actions.setSubmitting(false);
    if (err) {
      toast.error("Error when changing password ", {
        toastId: "error-occured",
      });
    } else {
      toast?.error("Unexpected error occurred", {
        toastId: "error-occurred",
      });
    }
  }
};
