import { ErrorMessage, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Col, Form, Input, Row } from "reactstrap";
import {
  initializeLoginInitialValues,
  LoginValidation,
} from "./LoginPageMethods/FormikMethods";
import { LoginSubmitHandler } from "./LoginPageMethods/LoginHandlers";
import TextError from "../../../Components/Errors/TextErrors";
import CustomButton from "../../../Components/Button/CustomButton";

const Login = () => {
  const navigate = useNavigate();
  const [LoginInitialValues] = useState(() => initializeLoginInitialValues());
  const isMobile = /Mobi/.test(navigator?.userAgent);

  return (
    <Container
      className="d-flex align-items-center justify-content-center header_background"
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
      }}
    >
      <Row
        className="d-flex align-items-center justify-content-center"
        style={{
          minWidth: "90vw",
          background: "rgba(254,254,254,255)",
          margin: "0",
          borderRadius: "5px",
        }}
      >
        <Col
          md={8}
          sm={12}
          style={{
            minHeight: isMobile ? "45vh" : "80vh",
            minWidth: "auto",
            borderRadius: "5px 0px 0px 5px",
          }}
          className="back"
        ></Col>{" "}
        {/* Apply the "back" class here */}
        <Col
          md={4}
          sm={12}
          style={{
            background: "rgba(238,237,244,255)",
            display: "flex",
            justifyContent: "center",
            minHeight: isMobile ? "45vh" : "80vh",
            borderRadius: "5px 5px 5px 5px",
            alignItems: "center",
          }}
        >
          <Formik
            validationSchema={LoginValidation}
            initialValues={LoginInitialValues}
            enableReinitialize
            onSubmit={(values, actions) =>
              LoginSubmitHandler(values, actions, navigate)
            }
          >
            {({
              values,
              isSubmitting,
              handleChange,
              touched,
              handleSubmit,
            }) => (
              <Form onSubmit={handleSubmit}>
                <h1 style={{ textAlign: "center" }}> Hello</h1>
                <h3 style={{ textAlign: "center" }}>Welcome Back</h3>
                <h3 style={{ textAlign: "center" }}>Good to see you Again!</h3>
                <div
                  className="form-wrap"
                  style={{
                    backdropFilter: "blur(20px)",
                    borderRadius: "10px",
                    padding: "24px",
                    maxWidth: "400px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <Row className="g-3">
                    <Col lg={12}>
                      <Input
                        type="text"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        placeholder="Username"
                      />
                      <ErrorMessage name="username" component={TextError} />
                    </Col>
                    <Col lg={12}>
                      <Input
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="password" component={TextError} />
                    </Col>

                    <Col md={12}>
                      <CustomButton
                        isLoading={isSubmitting}
                        label="LOG IN"
                        color="success"
                        className="sm submit-btn w-100"
                      />
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
