import * as Yup from "yup";
import dayjs from "dayjs";
import Cookies from "js-cookie";
const currentDate = dayjs().format("YYYY-MM-DD");
const userId = Cookies.get("PMUSERNAME");
// validation schema
export const DailyTaskValidation = Yup.object().shape({
  // taskId: Yup.string().required("Task is required"),
  name: Yup.string()
    .min(2, "Minimum 2 letters is required")
    .required("Daily task is required"),
  description: Yup.string()
    .min(10, "Minimum 10 letters is required")
    .required("Task description is required"),
  // assignedBy: Yup.string().required("Assignee is required"),
  // assignedTo: Yup.string().required("Assigned to is required"),
  // date: Yup.string().required("Date  today is required"),
  timeTaken: Yup.string()
    .min(0, "Minimum cannot be zero")
    .required("Time taken is required"),
  // projectId: Yup.string().required("Project Id is required"),
  // team: Yup.number()
  //   .min(0, "No team with id zero")
  //   .required("Team is required"),
  // status: Yup.string().required("Task Status is required"),
  // priority: Yup.string().required("Priority is required"),
});

// initial values
export const initializeDailyTaskInitialValues = () => {
  return {
    taskId: "",
    name: "",
    description: "",
    assignedBy: userId,
    assignedTo: userId,
    status: "OPEN",
    timeTaken: "",
    projectId: "",
    team: "",
    date: currentDate,
    priority: "LOW",
    taskType: "",
  };
};
